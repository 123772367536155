import React from "react";
import { Helmet } from "react-helmet";
import Spacer from "../components/Spacer";
import MultiMap from "../components/map/MultiMap";
import ETypeOfPartnership from "../types/enum/type-of-partnerships";

function HaendlermapPage() {
	return (
		<div>
			<Helmet>
				<title>Radl-Händler und Bike-Marken | FIRMENRADL</title>
				<meta
					name="description"
					content="Jetzt Top-Bike leasen und bis zu 35 % günstiger finanzieren als bei Kauf! Inklusive Reparaturen und Versicherung. Details und Rechenbeispiel auf firmenradl.at"
				/>
			</Helmet>
			<Spacer />
			<MultiMap type={ETypeOfPartnership.haendler} hideBanner={true} />
		</div>
	);
}

export default HaendlermapPage;
