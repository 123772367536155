// biome-ignore lint/suspicious/noExplicitAny: <explanation>
function parseNumber(number: any) {
	let numberClean = number;
	numberClean = numberClean.replaceAll(" ", "");
	numberClean = numberClean.replaceAll(".", "");

	return numberClean;
}

export default parseNumber;
