import React, { useState } from "react";
import "./Ablauf.scss";
import { Helmet } from "react-helmet";
import MapPopup from "../components/map/MapPopup";

function AblaufPage() {
	const [openMap, setOpenMap] = useState(false);

	return (
		<section className="ablaufSection">
			<MapPopup partnersOnly open={openMap} closePopup={setOpenMap} />
			<div className={"Ablauf"}>
				<Helmet>
					<title>Ablauf | FIRMENRADL</title>
					<meta
						name="description"
						content="Wir sind FIRMENRADL und bieten Arbeitnehmern und Arbeitgebern eine neue, innovative Art der Mobilität."
					/>
					<meta name="theme-color" content="#E0334C" />
				</Helmet>

				<h1 className={"noBreak"}>ABLÄUFE: TRITT FÜR TRITT ANS ZIEL</h1>
				<p>
					Vorab – wende dich gerne an{" "}
					<a href="mailto:office@firmenradl.at">office@firmenradl.at</a>, wenn
					es Fragen oder Unklarheiten gibt. Wir helfen dir gerne weiter.
				</p>
				<section id="arbeitnehmer">
					<div>
						<h2>Arbeitnehmer</h2>
						<h3>
							Hier erhältst du einen Leitfaden wie du zu deinem FIRMENRADL
							kommst.
						</h3>
						<ul>
							<li>
								Du <em>registrierst</em> dich mit allen notwendigen Daten direkt
								im Onlineportal von FIRMENRADL als Arbeitnehmer und wählst dabei
								deinen Arbeitgeber aus.
							</li>
							<li>
								Dein Arbeitgeber wird per Mail über deine Registrierung
								informiert, erteilt dir die Freigabe und du erhältst deine
								ID-Nummer per Mail.
							</li>
							<li>
								Das Wichtigste hast du nun erledigt. Auf geht’s zum{" "}
								<em>Fahrradhändler</em> deines Vertrauens. In der{" "}
								<button type={"button"} onClick={() => setOpenMap(true)}>
									KARTE
								</button>{" "}
								siehst du welche Radl-Händler bereits FIRMENRADL-Partner sind
								und ob dein Lieblingshändler bereits mit von der Partie ist.
								Sollte dies nicht der Fall sein, kannst du ihn gerne bitten sich
								bei FIRMENRADL zu registrieren.
							</li>
							<li>
								Beim Fachhändler suchst du dir dein bevorzugtes <em>Fahrrad</em>{" "}
								aus.
							</li>
							<li>
								Dieser informiert dich noch über den verpflichtenden{" "}
								<em>Versicherungsschutz</em>
							</li>
							<li>
								Nachdem du dein Wunschrad ausgesucht hast, gibst du dem
								Fachhändler deine ID-Nummer (beginnend mit <em>ANRF</em>)
								bekannt und ihr gebt zusammen noch die letzten Daten direkt in
								der FIRMENRADL-Onlineplattform ein. Im Anschluss sendet er den
								Antrag mit <em>deiner digitalen Unterschrift</em> an uns.
							</li>
							<li>
								Ab sofort kannst du alle <em>Vorzüge</em> deines FIRMENRADL
								genießen.
							</li>
							<li>
								Dein <em>aktiver Vertrag</em> scheint jetzt in unserem
								Onlineportal auf und ist für dich jederzeit einsehbar.
							</li>
						</ul>
						<div
							style={{
								padding: "56.25% 0 0 0",
								position: "relative",
								marginTop: "32px",
							}}
						>
							<iframe
								src="https://player.vimeo.com/video/754396729?h=b12f7f5722"
								title="Arbeitnehmer Video"
								style={{
									position: "absolute",
									top: 0,
									left: 0,
									width: "100%",
									height: "100%",
								}}
								frameBorder="0"
								allow="autoplay; fullscreen; picture-in-picture"
								allowFullScreen
							/>
						</div>
					</div>
				</section>
				<section id="arbeitgeber">
					<div>
						<h2>Arbeitgeber</h2>
						<h3>
							Hier erhältst du einen Leitfaden wie deine Mitarbeitenden zu ihren
							Bikes oder E-Bikes kommen.
						</h3>
						<ul>
							<li>
								Du <em>registrierst</em> dich als Arbeitgeber mit allen
								notwendigen Daten direkt im Onlineportal von FIRMENRADL.
							</li>
							<li>
								Dazu brauchen wir den <em>FIRMENRADL-Rahmenvertrag</em> und den{" "}
								<em>Leasing-Rahmenvertrag</em> (beides als PDF). Von der Person,
								welche die FIRMENRADL-Rahmenverträge unterfertigt hat, benötigt
								die Leasinggesellschaft auf Grund des Geldwäschegesetzes eine
								Ausweiskopie in elektronischer Form.
							</li>
							<li>
								Dann prüfen wir (FIRMENRADL) den Antrag und leiten ihn an die
								Leasinggesellschaft weiter. Nach der Bonitätsprüfung wird dein
								Unternehmen freigeschaltet.
							</li>
							<li>
								Jetzt weist du deinen <em>Mitarbeitenden</em> darauf hin, dass
								er sich im Onlineportal auf firmenradl.at als Arbeitnehmer
								registrieren und dabei dein Unternehmen auswählen muss.
							</li>
							<li>
								Sobald sich dein Mitarbeitender registriert hat, musst du ihn{" "}
								<em>
									für FIRMENRADL freigeben und davor ein Preislimit zwischen 749
									€ und 15.000 € festlegen.
								</em>
							</li>
							<li>
								Durch diesen Vorgang erhält dein Mitarbeitender eine ID-Nummer,
								mit der er sich beim von ihm ausgewählten Fachhändler meldet.{" "}
								<em>Der Antrag wird direkt vor Ort</em> abgewickelt und an uns
								(FIRMENRADL) gesendet.
							</li>
							<li>
								Im Zuge der Radübernahme wird auch die{" "}
								<em>Überlassungserklärung</em> von deinem Mitarbeitenden
								unterzeichnet – wenn ein individueller Text gewünscht wird,
								kannst du uns diesen jederzeit per Mail zukommen lassen, um ihn
								für dich zu hinterlegen.
							</li>
							<li>
								Mit dem <em>Tag der Abholung</em> des FIRMENRADLS{" "}
								<em>beginnt der Vertrag</em> mit der Laufzeit von{" "}
								<em>24, 36 oder 48 Monaten</em>. Nach Ablauf der Laufzeit hast
								du die Möglichkeit das Fahrrad zum Restwert zu erwerben und
								direkt an den Mitarbeitenden weiter zu verrechnen –{" "}
								<em>alternativ</em> kann die Verrechnung auch{" "}
								<em>über Firmenradl</em> an den Mitarbeitenden erfolgen!
							</li>
							<li>
								<em>Nach der Radübergabe</em> bekommst du die entsprechende{" "}
								<em>Rate von der Leasinggesellschaft</em> bekannt gegeben, um
								diese bei der <em>Lohn-/Gehaltsabrechnung</em> zu
								berücksichtigen.
							</li>
						</ul>
						<div
							style={{
								padding: "56.25% 0 0 0",
								position: "relative",
								marginTop: "32px",
							}}
						>
							<iframe
								src="https://player.vimeo.com/video/754403184?h=d9163d8eb0"
								title="Arbeitgeber Video"
								style={{
									position: "absolute",
									top: 0,
									left: 0,
									width: "100%",
									height: "100%",
								}}
								frameBorder="0"
								allow="autoplay; fullscreen; picture-in-picture"
								allowFullScreen
							/>
						</div>
					</div>
				</section>

				<section id={"radlhaendler"}>
					<div>
						<h2>Radl-Händler</h2>
						<h3>
							Hier erhältst du einen Leitfaden wie du als Radl-Händler beim
							FIRMENRADL teilnehmen kannst.
						</h3>
						<ul>
							<li>
								Du registrierst dich auf der Onlineplattform unter
								firmenradl.at, und lädst deine notwendigen Dokumente mit hoch.
								Danach kommt dein Antrag zu unserer internen Prüfung.{" "}
								<em>
									(WICHTIG: Kriterium für Rad-Händler ist die Kombination aus
									Fahrradverkauf und Fahrrad-Servicewerkstätte!)
								</em>
							</li>
							<li>
								Sobald dein Unternehmen <em>freigeschaltet</em> wurde, können
								deine Kunden FIRMENRADL nützen.
							</li>
							<li>
								Der Kunde gibt bei dir am Terminal seine <em>ID-Nummer</em>{" "}
								(beginnend mit <em>ANRF</em>) bekannt, mit welcher du den Kunden
								im Onlineportal findest.
							</li>
							<li>
								Jetzt prüfst du mittels <em>Ausweiskontrolle</em>, die Identität
								des Kunden und bestätigst dies via Checkbox.
							</li>
							<li>
								Hier erscheint ggf. ein vom Arbeitgeber{" "}
								<em>festgesetztes Limit</em>, in dem sich der Arbeitnehmer
								bewegen darf.
							</li>
							<li>
								Jetzt geht’s mit dem Kunden ans <em>Bike aussuchen</em>, das du
								dann direkt übergeben kannst.
							</li>
							<li>
								Nachdem der Verkauf abgeschlossen ist, bitte die offenen
								Datenfelder in der Maske ausfüllen und mit dem{" "}
								<em>Kunden zusammen online unterschreiben und abschicken</em>.
							</li>
						</ul>
						<div
							style={{
								padding: "56.25% 0 0 0",
								position: "relative",
								marginTop: "32px",
							}}
						>
							<iframe
								src="https://player.vimeo.com/video/754396848?h=2e4a5d343c"
								title="Händler Video"
								style={{
									position: "absolute",
									top: 0,
									left: 0,
									width: "100%",
									height: "100%",
								}}
								frameBorder="0"
								allow="autoplay; fullscreen; picture-in-picture"
								allowFullScreen
							/>
						</div>
					</div>
				</section>
			</div>
			<script src="https://player.vimeo.com/api/player.js" />
		</section>
	);
}

export default AblaufPage;
