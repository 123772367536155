import React from "react";
import Hero from "../components/Hero";
import "./Gewinnspiel.scss";
import { Helmet } from "react-helmet";

const GewinnspielPage = () => {
	return (
		<div>
			<Helmet>
				<title>Gewinnspiele | FIRMENRADL Bike-Leasing</title>
				<meta
					name="description"
					content="Mache bei unseren Gewinnspielen mit! Es warten immer wieder tolle Preise auf dich. Schau immer wieder auf firmenradl.at vorbei und sei dabei!"
				/>
				<meta name="theme-color" content="#DBE283" />

				<meta
					property="og:title"
					content="Gewinnspiele | FIRMENRADL Bike-Leasing"
				/>
				<meta
					property="og:image"
					content="img/assets/mood_lime_full.jpg?w=512"
				/>
				<meta
					property="og:description"
					content="Mache bei unseren Gewinnspielen mit! Es warten immer wieder tolle Preise auf dich. Schau immer wieder auf firmenradl.at vorbei und sei dabei!"
				/>
			</Helmet>
			<div className="AnimatedKringelParent">
				<Hero
					headline="LEASEN & GEWINNEN"
					text="<p>Halte deinen FIRMENRADL Moment fest und profitiere davon! Du hast die Chance, eine hochwertige Garmin Sportuhr zu gewinnen!</p>"
					image="assets/mood_red_hero2.jpg"
					imageMobile="assets/mood_red_full.jpg"
				/>
			</div>
			<div className={"Gewinnspiel"}>
				<h1 className={"noBreak"}>
					TEILNAHMEBEDINGUNGEN
					<br />
					FIRMENRADL GEWINNSPIEL
				</h1>
				<p>
					Verantwortliche dieses Gewinnspiels ist die INTERSPORT AUSTRIA
					Gesellschaft m.b.H., Flugplatzstraße 10, 4600 Wels („Veranstalterin“).
					Eine Teilnahme am Gewinnspiel ist ausschließlich zu den hier
					angeführten Bedingungen möglich.
				</p>
				<p>
					<u>
						<b>
							Mit der Teilnahme am Gewinnspiel stimmt der Teilnehmer diesen
							Teilnahmebedingungen ausdrücklich zu.
						</b>
					</u>
				</p>
				<h2>Gewinn</h2>
				<p>
					Unter allen Teilnehmern werden insgesamt 10 Garmin GPS-Sportuhren
					„Forerunner 265“ verlost.
				</p>
				<p>
					Sollte die Veranstalterin aus von ihr nicht vertretbaren Gründen den
					Gewinn nicht zur Verfügung stellen können, so behält sie sich vor,
					einen gleichwertigen Ersatz zu liefern.
				</p>

				<h2>Gewinnspielzeitraum</h2>
				<p>
					Das Gewinnspiel startet am 01. Juni 2024 um 00:00 Uhr und endet am 31.
					März 2025 um 23:59 Uhr.
				</p>

				<h2>Teilnahmemechanik</h2>
				<p>
					Die Teilnahme ist nur innerhalb der in der Gewinnspielbeschreibung
					genannten Mechanik möglich.
				</p>

				<ul>
					<li>
						Lass dich im oder vor dem Shop deines Händlers mit deinem neuen Bike
						und dem FIRMENRADL Schild fotografieren
					</li>
					<li>
						Poste anschließend das Foto auf Instagram mit den folgenden
						Hashtags: <br />
						<b>#firmenradl</b> & <b>#meinfirmenradl</b>
					</li>
					<li>Markiere FIRMENRADL und den Account deines Händlers</li>
					<li>
						Bitte beachte, dass dein Instagram-Account auf öffentlich gestellt
						ist, da wir ansonsten das Foto nicht sehen und dich folglich auch
						nicht bei der Auslosung berücksichtigen können!
					</li>
				</ul>

				<h2>Teilnahmeberechtigung</h2>
				<p>
					Teilnahmeberechtigt sind alle natürlichen Personen mit Wohnsitz in
					Österreich. Die Teilnahme am Gewinnspiel ist ab 18 Jahren möglich.
				</p>

				<h2>Teilnahmeausschluss</h2>
				<p>
					Bei Mehrfachteilnahme oder falschen Angaben kann ein Ausschluss vom
					Gewinnspiel erfolgen. Ausgeschlossen von der Teilnahme sind
					Mitarbeiter der INTERSPORT AUSTRIA Gesellschaft m.b.H. und der
					INTERSPORT Digital Business GmbH. An der Umsetzung des Gewinnspiels
					beteiligte Personen sind von der Teilnahme ebenfalls ausgeschlossen.
					Pro Person ist die Teilnahme nur einmal gültig. Die Veranstalterin
					behält sich das Recht vor, Personen, die gegen die
					Teilnahmebedingungen verstoßen oder durch technische Manipulation
					sowie durch automatisierte Eintragungen versuchen, das Gewinnspiel zu
					beeinflussen, von diesem auszuschließen.
				</p>

				<h2>Gewinnermittlung</h2>
				<p>
					Die Gewinner, die die oben genannte Teilnahmemechanik erfüllt haben,
					werden nach Ablauf des Teilnahmezeitraums per Losverfahren unter
					Ausschluss der Öffentlichkeit gezogen und anschließend via Instagram
					benachrichtigt. Die Kontaktaufnahme erfolgt einmalig. Erfolgt
					innerhalb eines Zeitraumes von 24 Stunden nach Kontaktaufnahme seitens
					des Gewinners keine Rückmeldung, wird der Gewinn neuerlich ausgelost.
					Eine nachträgliche Rückmeldung nach 24 Stunden wird nicht mehr
					berücksichtigt.
				</p>

				<p>
					Sollten die angegebenen Kontaktdaten der Teilnehmer fehlerhaft sein,
					ist die Veranstalterin nicht verpflichtet, richtige Daten
					auszuforschen. Etwaige Nachteile, die sich aus der Angabe fehlerhafter
					Kontaktdaten ergeben, gehen zu Lasten der Teilnehmer.
				</p>

				<h2>Datenschutz</h2>
				<p>
					Zum Zwecke der Ausrichtung und Durchführung des Gewinnspiels
					verarbeitet die Verantwortliche die im Rahmen des Gewinnspiels bekannt
					gegebenen personenbezogenen Daten (Name, Adresse) der Teilnehmer.
					Sämtliche erhobenen Daten werden spätestens 30 Tage nach Ende des
					Gewinnspiels gelöscht. Allgemeine Informationen und Details zum
					Datenschutz findest du hier.
				</p>

				<h2>Sonstiges</h2>
				<p>
					Durch die Teilnahme am Gewinnspiel entstehen den Teilnehmern keine
					Kosten und ist diese unentgeltlich.
				</p>

				<p>
					Die Veranstalterin behält sich das Recht vor, ohne Vorankündigung
					Änderungen sowie Ergänzungen des Gewinnspiels vorzunehmen oder das
					Gewinnspiel ganz abzubrechen. Dies gilt insbesondere bei höherer
					Gewalt oder falls das Gewinnspiel aus organisatorischen, technischen
					oder rechtlichen Gründen nicht durchgeführt oder fortgesetzt werden
					kann.
				</p>

				<p>
					Im Falle von Rechtsstreitigkeiten ist ausschließlich das Recht der
					österreichischen Republik maßgebend. Als Gerichtsstand wird der Sitz
					der Veranstalterin, soweit rechtlich zulässig, vereinbart.
				</p>

				<p>
					Die Veranstalterin haftet nur für Schäden, welche vorsätzlich oder
					grob fahrlässig verursacht wurden. Diese Haftungsbeschränkung gilt
					nicht für Schäden nach dem Produkthaftungsgesetz (PHG). Weiters gilt
					die Haftungsbeschränkung auch nicht für die Verletzung vertraglicher
					Hauptpflichten durch die Veranstalterin.
				</p>
			</div>
		</div>
	);
};

export default GewinnspielPage;
