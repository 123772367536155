import React, { useEffect, useState } from "react";
import Autocomplete from "react-google-autocomplete";
import { FixedSizeList as List } from "react-window";
import "./styles/MultiMap.scss";
import type { Libraries } from "@react-google-maps/api";
import { filter } from "lodash";
// @ts-expect-error
import ic_location from "../../assets/ic_location.svg";
import { generateMarker } from "../../helpers/generateMarker";
import { useCachedData } from "../../hooks/useCachedData";
import useMapStore from "../../hooks/useMapStore";
import useSize from "../../hooks/useSize";
import ETypeOfPartnership from "../../types/enum/type-of-partnerships";
import type {
	IFirm,
	IMarkerDaniel,
	IPartner,
	TTypeOfPartnership,
} from "../../types/types";
import MapFirmenradl from "./MapFirmenradl";
import MultiMapResultRow from "./MultiMapResultRow";

// daniel old key: "AIzaSyBzOBnmJmz3YqWzNSfJ8WKijLDUf0mEpQk"
// new key: "AIzaSyD45mWtkR-uL08b9ChQNizPufbp9zBeAUM"
// latest key: "AIzaSyBS_tA6hX55fewA0DwI2BWvzHfqihpXBD0"

const GOOGLE_MAPS_API_KEY =
	process.env.NODE_ENV === "development"
		? "AIzaSyBzOBnmJmz3YqWzNSfJ8WKijLDUf0mEpQk"
		: "AIzaSyBS_tA6hX55fewA0DwI2BWvzHfqihpXBD0";
const GOOGLE_LIBRARIES: Libraries = ["places"];

export { GOOGLE_MAPS_API_KEY, GOOGLE_LIBRARIES };

export type MultiMapProps = {
	headerText?: string;
	type: TTypeOfPartnership;
	hideBanner: boolean;
};

function MultiMap({ headerText, type, hideBanner }: MultiMapProps) {
	const [mode, setMode] = useState<TTypeOfPartnership>(type);

	const changeMode = (newMode: TTypeOfPartnership) => {
		return setMode(newMode);
	};

	return (
		<div className="MultiMap">
			<MultiMapHeader
				headerText={headerText}
				type={type}
				mode={mode}
				changeMode={changeMode}
			/>
			<MultiMapContent type={type} mode={mode} />
			<MultiMapBanner hideBanner={hideBanner} />
		</div>
	);
}

export default MultiMap;

function MultiMapHeader({
	headerText,
	type,
	mode,
	changeMode,
}: {
	headerText?: string;
	type: TTypeOfPartnership;
	mode?: TTypeOfPartnership;
	changeMode: (newMode: TTypeOfPartnership) => void;
}) {
	if (type === ETypeOfPartnership.haendler) {
		return (
			<div className="MultiMap-Header">
				<h2>{headerText || "Radl-Händler"}</h2>
			</div>
		);
	}
	if (type === ETypeOfPartnership.arbeitgeber) {
		return (
			<div className="MultiMap-Header">
				<h2>{headerText || "Partner"}</h2>
			</div>
		);
	}

	return (
		<div className="MultiMap-Header">
			<h2>Radl-Händler & Partner</h2>
			<div className="radioGroupContainer">
				<div className="radioGroup">
					<label
						className={
							mode === ETypeOfPartnership.all ? "checked" : "not-checked"
						}
					>
						<input
							type="radio"
							name="mode"
							checked={mode === ETypeOfPartnership.all}
							onChange={() => changeMode(ETypeOfPartnership.all)}
						/>{" "}
						Alle
					</label>
					<label
						className={
							mode === ETypeOfPartnership.haendler ? "checked" : "not-checked"
						}
					>
						<input
							type="radio"
							name="mode"
							checked={mode === ETypeOfPartnership.haendler}
							onChange={() => changeMode(ETypeOfPartnership.haendler)}
						/>{" "}
						Radl-Händler
					</label>
					<label
						className={
							mode === ETypeOfPartnership.arbeitgeber
								? "checked"
								: "not-checked"
						}
					>
						<input
							type="radio"
							name="mode"
							checked={mode === ETypeOfPartnership.arbeitgeber}
							onChange={() => changeMode(ETypeOfPartnership.arbeitgeber)}
						/>{" "}
						Arbeitgeber
					</label>
				</div>
			</div>
		</div>
	);
}

function MultiMapContent({
	type,
	mode,
}: { type: TTypeOfPartnership; mode?: TTypeOfPartnership }) {
	const watch = mode || type;

	const { firms, partners, markers: makersFromServer } = useCachedData();
	const [searchText, setSearchText] = useState("");
	const { setZoom, handleMapPlaceSelected, detectLocation, setActiveWindow } =
		useMapStore();
	const windowSize = useSize();

	useEffect(() => {
		windowSize[1] < 400 ? setZoom(6) : setZoom(7);
	}, [setZoom, windowSize]);

	const markersToDraw: string[] = [];
	const resultsToDraw: (IFirm | IPartner)[] = [];
	const markers: IMarkerDaniel[] = [];
	const listItems: (IFirm | IPartner)[] = [];

	const search = searchText.toLowerCase();

	if (search === "" || search.length === 0) {
		if (
			watch === ETypeOfPartnership.all ||
			watch === ETypeOfPartnership.arbeitgeber
		) {
			// biome-ignore lint/complexity/noForEach: <explanation>
			Object.keys(firms).forEach((key) => {
				const data = firms[key];
				data.type = ETypeOfPartnership.arbeitgeber;
				resultsToDraw.push(data);
			});
		}

		if (
			watch === ETypeOfPartnership.all ||
			watch === ETypeOfPartnership.haendler
		) {
			// biome-ignore lint/complexity/noForEach: <explanation>
			Object.keys(partners).forEach((key) => {
				const data = partners[key];
				data.type = ETypeOfPartnership.haendler;
				resultsToDraw.push(data);
			});
		}
	} else {
		if (
			watch === ETypeOfPartnership.all ||
			watch === ETypeOfPartnership.arbeitgeber
		) {
			const filteredFirms = filter(firms, (o) => {
				if (o.title.toLowerCase().indexOf(search) >= 0) return true;
				if (o.city.toLowerCase().indexOf(search) >= 0) return true;
				if (o.zip.toLowerCase().indexOf(search) >= 0) return true;
				return o.street.toLowerCase().indexOf(search) >= 0;
			});

			filteredFirms.map((data) => {
				return resultsToDraw.push({
					...data,
					type: ETypeOfPartnership.arbeitgeber,
				});
			});
		}

		if (
			watch === ETypeOfPartnership.all ||
			watch === ETypeOfPartnership.haendler
		) {
			const filteredPartners = filter(partners, (o) => {
				if (o.title.toLowerCase().indexOf(search) >= 0) return true;
				if (o.city.toLowerCase().indexOf(search) >= 0) return true;
				if (o.zip.toLowerCase().indexOf(search) >= 0) return true;
				if (o.street.toLowerCase().indexOf(search) >= 0) return true;
				return o.bikeSearchString.toLowerCase().indexOf(search) >= 0;
			});

			filteredPartners.map((data) => {
				return resultsToDraw.push({
					...data,
					type: ETypeOfPartnership.haendler,
				});
			});
		}
	}

	// biome-ignore lint/complexity/noForEach: <explanation>
	resultsToDraw
		.sort((a, b) => {
			if (a.premiumPosition) {
				if (!b.premiumPosition) {
					return -1;
				}
			}
			return 0;
		})
		.forEach((data) => {
			if (!markersToDraw.includes(data.marker)) {
				if (data.marker) {
					markersToDraw.push(data.marker);
				}
			}
			if (data.location.location) {
				listItems.push(data);
			}
		});

	// biome-ignore lint/complexity/noForEach: <explanation>
	markersToDraw.forEach((markerToDraw) => {
		const data = makersFromServer[markerToDraw];
		if (data.location) {
			markers.push(generateMarker(data, data.type, markerToDraw));
		}
	});

	let placeholder = "Nach Händlern, Marken & Arbeitgebern suchen...";
	if (type === ETypeOfPartnership.arbeitgeber)
		placeholder = "Nach Radl-Händlern und Marken suchen...";
	if (type === ETypeOfPartnership.haendler)
		placeholder = "Nach Arbeitgebern suchen...";

	return (
		<div className="MultiMap-Content">
			<div className="MultiMap-List">
				<div className="row MultiMap-Search">
					<div className="col text-center">
						<div className="place">
							{process.env.NODE_ENV !== "development" ? (
								<Autocomplete
									apiKey={GOOGLE_MAPS_API_KEY}
									onPlaceSelected={(place) => handleMapPlaceSelected(place)}
									options={{
										componentRestrictions: { country: "at" },
									}}
									className="searchbox"
									// @ts-expect-error
									placeholder="Nach Ort suchen..."
								/>
							) : (
								<></>
							)}

							<button onClick={() => detectLocation()} type="button">
								<img src={ic_location} alt="Meine Position erkennen" />
							</button>
						</div>
						<input
							className="searchbox"
							type="text"
							name="searchText"
							placeholder={placeholder}
							value={searchText}
							onChange={(e) => {
								setSearchText(e.target.value);
								setActiveWindow("");
							}}
						/>
					</div>
				</div>
				<div className="MultiMap-Group">
					{listItems.length > 0 ? (
						<List
							className="List"
							height={window.innerHeight * 0.8}
							itemCount={listItems.length}
							itemSize={100}
							width={400}
							itemData={listItems}
						>
							{MultiMapResultRow}
						</List>
					) : (
						<div className="noSearchResult">
							<span>Deine Suche hat leider kein Ergebnis gebracht.</span>
						</div>
					)}
				</div>
			</div>
			<div className="MultiMap-Map">
				<MapFirmenradl initialMarkers={markers} />
			</div>
		</div>
	);
}

function MultiMapBanner({ hideBanner }: { hideBanner: boolean }) {
	if (!hideBanner) {
		return (
			<div className="MultiMap-Banner">
				<p>
					Dein Radl-Händler oder Arbeitgeber ist noch nicht bei uns registriert?
					<br />
					Kein Problem! Kontaktiere uns, wir kümmern uns darum.
				</p>
				<a
					href="mailto:office@firmenradl.at"
					className="button"
					target="_blank"
					rel="noreferrer"
				>
					Kontaktiere uns
				</a>
			</div>
		);
	}

	return null;
}
