import type {
	IMarker,
	IMarkerDaniel,
	TTypeOfPartnership,
} from "../types/types";
import { getBaseUrl } from "./getBaseUrl";

function generateMarker(
	data: IMarker,
	type: TTypeOfPartnership | string,
	id: string,
): IMarkerDaniel {
	const baseUrl = getBaseUrl(true, "/img/", false, false, true);

	const partnerImage = `${baseUrl}assets/maps_red.png?w=32`;
	const firmImage = `${baseUrl}assets/maps_blue.png?w=32`;
	const mixedImage = `${baseUrl}assets/maps_black.png?w=32`;

	let icon = mixedImage;

	if (data.partners.length && data.firms.length) {
		icon = mixedImage;
	} else if (data.partners.length) {
		icon = partnerImage;
	} else if (data.firms.length) {
		icon = firmImage;
	}

	return {
		...data,
		type,
		id,
		icon: {
			url: icon,
			scaledSize: { width: 32, height: 32 },
		},
	};
}

export { generateMarker };
