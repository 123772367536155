import React from "react";
import {
	LazyLoadImage,
	type LazyLoadImageProps,
} from "react-lazy-load-image-component";
import { getBaseUrl } from "../helpers/getBaseUrl";

function limitNumberWithinRange(
	num: string | number,
	min = 1,
	max = 20,
): number {
	const MIN = min;
	const MAX = max;
	let parsed: number;

	if (typeof num === "string") {
		parsed = Number.parseInt(num, 10);
		if (Number.isNaN(parsed)) {
			throw new Error("Invalid number string");
		}
	} else {
		parsed = num;
	}
	return Math.min(Math.max(parsed, MIN), MAX);
}

type Props = {
	src?: string | null | undefined;
	height: number;
	width: number;
	className?: string;
	crop?: boolean;
	logoUrl?: boolean;
	imageProps?: LazyLoadImageProps;
};

const Image = ({
	src,
	height = 0,
	width = 580,
	className,
	crop = false,
	logoUrl = false,
	imageProps,
}: Props) => {
	const baseUrl = getBaseUrl(
		true,
		logoUrl ? "/logo/" : "/img/",
		false,
		false,
		true,
	);

	// reducing width and height to a multiple of 8 to achieve less caching and resizing
	width = width - (width % 8);
	width = Math.round(limitNumberWithinRange(width, 16, 2048));

	if (height > 0) {
		height = height - (height % 8);
		height = Math.round(limitNumberWithinRange(height, 16, 2048));
	}

	const cropParam = !crop ? "" : "&fit=crop"; // crop by default

	const w = `&w=${width}`;
	const h = height > 0 ? `&h=${height}${cropParam}` : "";
	const wSmall = `&w=${width}`;

	// @ts-expect-error
	if (src.indexOf("placeholder") > 0) {
		// image is a placeholder, let's avoid bad http calls
		return null;
	}

	const params = `${w}${h}`;
	const basicSrc = `${baseUrl}${src}?fm=pjpg${wSmall}`;

	return (
		<picture className={className}>
			<source
				srcSet={`${baseUrl}${src}?fm=webp${params}&q=90 1x, ${baseUrl}${src}?fm=webp${params}&dpr=1.5&q=80 1.5x, ${baseUrl}${src}?fm=webp${params}&dpr=2&q=75 2x`}
				type="image/webp"
			/>
			<source
				srcSet={`${baseUrl}${src}?fm=pjpg${params}&q=90 1x, ${baseUrl}${src}?fm=pjpg${params}&dpr=1.5&q=80 1.5x, ${baseUrl}${src}?fm=pjpg${params}&dpr=2&q=75 2x`}
				type="image/jpeg"
			/>

			<LazyLoadImage src={basicSrc} {...imageProps} />
		</picture>
	);
};

export default Image;
