import React, { type CSSProperties, type ReactNode } from "react";
import "./styles/MultiMapResult.scss";
import { getBaseUrl } from "../../helpers/getBaseUrl";
import useMapStore from "../../hooks/useMapStore";
import type { IFirm, IPartner } from "../../types/types";
import Image from "../Image";

function MultiMapResultRow(props: {
	// TODO fix types
	data: (IPartner | IFirm)[];
	index: number;
	style: CSSProperties | undefined;
}) {
	const { setCenter, setZoom, setActiveWindow } = useMapStore();
	const { style, index } = props;
	const { title, street, logo, location, zip, city, marker } =
		props.data[index];

	let img: ReactNode;

	if (logo.indexOf(".svg") > 0) {
		// image is an svg
		const baseUrl = getBaseUrl(true, "/img/");

		img = <img src={`${baseUrl}${logo}`} alt={title} />;
	} else if (logo.length > 0 && logo.indexOf("placeholder") === -1) {
		img = <Image src={logo} width={64} height={64} crop={false} logoUrl />;
	}

	return (
		<button
			className="MultiMapResult"
			style={style}
			onClick={() => {
				setActiveWindow(marker);
				setCenter(location.location);
				setZoom(14);
			}}
			type="button"
		>
			<div className="MultiMapResult-text">
				<h4>{title}</h4>
				<div>
					<p>
						{street} <br />
						{zip} {city}
					</p>
				</div>
			</div>
			{img ? <div className="MultiMapResult-image">{img}</div> : <></>}
		</button>
	);
}

export default MultiMapResultRow;
