import { ContentState, EditorState, convertToRaw } from "draft-js";
import React from "react";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";

class AdminPrivacy extends React.Component {
	constructor(props) {
		super(props);

		const contentBlock = htmlToDraft(
			typeof props.content === "string" ? props.content : "",
		);

		if (contentBlock) {
			const contentState = ContentState.createFromBlockArray(
				contentBlock.contentBlocks,
			);
			const editorState = EditorState.createWithContent(contentState);
			this.state = {
				editorState,
			};
		}
	}

	onEditorStateChange(editorState) {
		this.setState({
			editorState,
		});

		this.props.onChange(
			draftToHtml(convertToRaw(editorState.getCurrentContent())),
		);
	}

	render() {
		return (
			<div className={"admin-cms"}>
				<label htmlFor={"body"}>Inhalt der Datenschutz Seite</label>
				<Editor
					editorState={this.state.editorState}
					toolbarClassName="toolbarClassName"
					wrapperClassName="wrapperClassName"
					editorClassName="editorClassName"
					toolbar={{
						options: ["inline", "blockType", "list", "link", "history"],
						inline: {
							inDropdown: false,
							options: ["bold", "italic", "underline", "strikethrough"],
						},
						blockType: {
							inDropdown: false,
							options: [
								"Normal",
								"H1",
								"H2",
								"H3",
								"H4",
								"H5",
								"H6",
								"Blockquote",
							],
						},
					}}
					onEditorStateChange={(editorState) =>
						this.onEditorStateChange(editorState)
					}
				/>
			</div>
		);
	}
}

export default AdminPrivacy;
