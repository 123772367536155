import React from "react";
import "./Steps.scss";
import "./Step.scss";
import { Link } from "react-router-dom";
import { getRegisterUrl } from "../helpers/getRegisterUrl";
import type { IStep } from "../types/types";
import AnimatedKringel from "./AnimatedKringel";

function Steps({ steps }: { steps: IStep[] }) {
	return (
		<div className="Steps AnimatedKringelParent">
			<AnimatedKringel text={"Tritt für Tritt"} marginBottomMobile={"-200px"} />
			<div className="Steps-text">
				{steps.map((step, index) => (
					<Step number={index + 1} key={`step_${step.title}`} step={step} />
				))}
			</div>
			<div className="Steps-Banner">
				<a
					href={getRegisterUrl()}
					className="button"
					target="_blank"
					rel="noreferrer"
				>
					Abgefahren! Gleich anmelden
				</a>
				<Link to="/ablauf">Detailliertere Infos und Hilfe zur Anmeldung</Link>
			</div>
		</div>
	);
}

export function Step({ step, number }: { step: IStep; number: number }) {
	const { title, text } = step;
	return (
		<div className="Step">
			<div className="Step-Headline">
				<div className="Step-Bubble">
					<h3>{title}</h3>
					<h4>{number}</h4>
				</div>
			</div>
			<p>{text}</p>
		</div>
	);
}

export default Steps;
