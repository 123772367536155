import { type RefObject, useEffect, useState } from "react";

const useIntersectionObserver = (reference: RefObject<Element>): boolean => {
	const [isVisible, setIsVisible] = useState<boolean>(false);

	useEffect(() => {
		const handleIntersect = (
			entries: IntersectionObserverEntry[],
			observer: IntersectionObserver,
		) => {
			if (entries[0].isIntersecting) {
				setIsVisible(true);
				observer.unobserve(entries[0].target);
				observer.disconnect();
			}
		};

		const observer = new IntersectionObserver(handleIntersect);

		if (reference) {
			observer.observe(reference.current as Element);
		}

		return () => observer.disconnect();
	}, [reference]);

	return isVisible;
};

export default useIntersectionObserver;
