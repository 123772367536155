import React from "react";
import { Helmet } from "react-helmet";

function ImprintPage() {
	return (
		<div className={"Imprint Rechtliches"}>
			<Helmet>
				<title>Impressum | FIRMENRADL</title>
				<meta
					name="description"
					content="Wir sind FIRMENRADL und bieten Arbeitnehmern und Arbeitgebern eine neue, innovative Art der Mobilität."
				/>
				<meta name="robots" content="noindex, nofollow" />
				<meta name="theme-color" content="#E0334C" />
			</Helmet>
			<div className="fr-view u-clearfix u-rich-text u-text u-text-3">
				<h1>Impressum</h1>
				<p>INTERSPORT Österreich:</p>
				<p>
					INTERSPORT Austria Gesellschaft mbH
					<br />
					Flugplatzstraße 10, 4600 Wels
				</p>
				<p>Hotline.: 0800 / 20 19 09</p>
				<p>
					Firmenbuchnummer: 101648h
					<br />
					Firmenbuchgericht: Landesgericht Wels <br />
					UID-Nummer: ATU25138106
					<br />
					DVR-Nummer: 0002038
				</p>
				<p>
					Behörde gemäß ECG: Magistrat der Stadt Wels
					<br />
					Unternehmensgegenstand: Handel mit Waren aller Art
				</p>
				<p>
					Mitglied der Wirtschaftskammer OÖ, Sparte Handel, Landesgremium,
					Handel mit Mode und Freizeitartikeln
				</p>
				<p>
					Das Webportal dient zur Information über Angebote im Rahmen des
					Firmenradl-Konzepts und zur Vermittlung von Leasingverträgen. Auf die
					Tätigkeit der INTERSPORT Austria Gesellschaft mbH ist die
					Gewerbeordnung 1994 anwendbar. Diese ist unter www.ris.bka.gv.at
					abrufbar.&nbsp;
				</p>
				<p>Freiwilliger Verhaltenskodex: Verhaltenskodex Trustmark Austria</p>
			</div>
		</div>
	);
}

export default ImprintPage;
