import React, { useRef } from "react";
import { useEffect, useState } from "react";
import "./Rechner.scss";
import CurrencyInput from "react-currency-input-field";
import TextTransition, { presets } from "react-text-transition";
import type { TextTransitionProps } from "react-text-transition/src/components/TextTransition";
// @ts-expect-error
import Awards_EC_Firmenradl_AT from "../assets/@2x_Awards_EC_Firmenradl_AT_2024.png";
// @ts-expect-error
import icon_arrow_down from "../assets/ic_arrow_down.svg";
import { getRegisterUrl } from "../helpers/getRegisterUrl";
import parseNumber from "../helpers/parseNumber";
import { useCachedData } from "../hooks/useCachedData";
import useCalculateParams, {} from "../hooks/useCalculateParams";
import EEndpoints from "../types/enum/endpoints";
import type { ICalculatorInput, ILeasing } from "../types/types";
import Image from "./Image";
import RechnerModal from "./RechnerModal";
import Tooltip from "./Tooltip";

let timer: NodeJS.Timeout | string | number | undefined;

function initialCalculationParams(
	insuranceType: string,
	leasingDuration: string,
	residualValue: string,
): ICalculatorInput {
	return {
		Einkommen: 3590,
		FahrradPreis: 4999,
		Versicherung: Number(insuranceType),
		Laufzeit: Number(leasingDuration),
		Vorsteuerabzug: true,
		SvAbzug: true,
		Restwertvariante: Number(residualValue),
		Pendlerpauschale: {
			Id: 1,
			Km: 80,
		},
		Familienbonus: {
			Id: 1,
			KinderBis17: 3,
			KinderAb18: 3,
		},
	};
}

type Props = {
	className?: string;
	internal: boolean;
};

function Rechner({ className, internal = false }: Props) {
	const [open, setOpen] = useState(false);
	const [error, setError] = useState<string | "">("");
	const { insurances, leasingDurations, residualValues } = useCachedData();

	const [priceDirection, setPriceDirection] =
		useState<TextTransitionProps["direction"]>("down");
	const [discountDirection, setDiscountDirection] =
		useState<TextTransitionProps["direction"]>("down");

	const { form, setForm, calculationParams } = useCalculateParams({
		insuranceType: insurances[0].Id,
		residualValue: residualValues[0].Id,
		leasingDuration: leasingDurations[0].Id,
		einkommen: 3590,
		price: 4999,
		vorsteuerabzug: true,
		pendlerpauschaleActive: false,
		pendlerpauschale: 50,
		familienbonusActive: false,
		fbBis17: 0,
		fbAb18: 0,
	});

	const [advancedMode, setAdvancedMode] = useState<boolean>(true);

	type ICalculator = ILeasing & { calculationParams: ICalculatorInput } & {
		gesamtPreis: number | string;
	};

	// inital rendering needs to be with strings otherwise the animation is wrong at the first content paint
	const [calculator, setCalculator] = useState<ICalculator>({
		MonatlicheZahlung: "113.43 €", // 113.42911216806851
		RateNetto: 197.78, // 197.780530711002
		RateBrutto: 237.34, // 237.33663685320238
		ErsparnisInEuro: "1.526,85 €", // 1526.8513079663558
		ErsparnisInProzent: 31, // 30.54313478628437
		Restwert: "749.85 €", // 749.85,
		RestwertInProzent: 15,
		MonatlicheZahlungAG: 197.78, // 197.780530711002
		RGG: 83.75, // 83.74869397033895
		Versicherung: 24,
		SteuerpflichtBetrag: 838.73, // 838.7252214
		Error: null,
		calculationParams: initialCalculationParams(
			form.insuranceType,
			form.leasingDuration,
			form.residualValue,
		),
		gesamtPreis: "3.472,15 €", // 3472.15, // 3240.53
	});

	useEffect(() => {
		if (
			!calculator.calculationParams ||
			// @ts-ignore TODO: fix this
			calculator.calculationParams !== calculationParams
		) {
			if (timer) {
				clearTimeout(timer);
			}

			const devUrl = "https://test-firmenradl.intersport.at";
			const prodUrl = "https://firmenradl.at";

			timer = setTimeout(async () => {
				try {
					const response = await fetch(
						`${devUrl}/rest/${EEndpoints.calculateLeasing}`,
						{
							method: "POST",
							headers: {
								Accept: "text/plain",
								"Content-Type": "application/json",
							},
							body: calculationParams,
						},
					);

					if (!response.ok) {
						const errorText = await response.text(); // Read the response text
						if (
							errorText.includes("$.FahrradPreis") || // field empty Fahrrad-Preis
							errorText.includes("$.Einkommen") || // field empty Einkommen
							errorText.includes("klein (Id=1)") || // field Pendlerpauschale unter 19 KM
							errorText.includes("$.Familienbonus.KinderBis17") || // field empty Kinder bis 17 Jahre
							errorText.includes("$.Familienbonus.KinderAb18") || // field empty Kinder ab 18 Jahre
							errorText.includes("$.Pendlerpauschale.Km") // field empty Pendlerpauschale
						) {
							setError("");
						} else {
							setError(errorText);
						}
					}

					const data: ILeasing = await response.json();
					setError("");

					if (JSON.stringify(data) !== JSON.stringify(calculator)) {
						if (response.status === 200) {
							if (calculator.ErsparnisInEuro > data.ErsparnisInEuro) {
								setDiscountDirection("up");
								setPriceDirection("down");
							} else {
								setDiscountDirection("down");
								setPriceDirection("up");
							}

							const paramsAsObject = JSON.parse(calculationParams);

							setCalculator({
								...data,
								RestwertInProzent: data.RestwertInProzent.toLocaleString(
									"de-DE",
									{
										maximumFractionDigits: 0,
									},
								),
								ErsparnisInEuro: data.ErsparnisInEuro.toLocaleString("de-DE", {
									style: "currency",
									currency: "EUR",
								}),
								ErsparnisInProzent: data.ErsparnisInProzent.toLocaleString(
									"de-DE",
									{
										maximumFractionDigits: 0,
									},
								),
								// @ts-ignore TODO: fix this
								calculationParams: calculationParams,
								gesamtPreis: (
									Number(data.Restwert) +
									Number(data.MonatlicheZahlung) *
										Number(paramsAsObject.Laufzeit)
								).toLocaleString("de-DE", {
									style: "currency",
									currency: "EUR",
								}),
								MonatlicheZahlung: data.MonatlicheZahlung.toLocaleString(
									"de-DE",
									{
										style: "currency",
										currency: "EUR",
									},
								),
								MonatlicheZahlungAG: data.MonatlicheZahlungAG.toLocaleString(
									"de-DE",
									{
										style: "currency",
										currency: "EUR",
									},
								),
								Restwert: data.Restwert.toLocaleString("de-DE", {
									style: "currency",
									currency: "EUR",
								}),
							});
						}
					}
				} catch (err) {
					console.error("Error:", err);
				} finally {
					clearTimeout(timer);
					timer = undefined;
				}
			}, 300);
		}
	}, [
		calculator,
		calculationParams,
		setCalculator,
		setDiscountDirection,
		setPriceDirection,
	]);

	return (
		<>
			{open && <RechnerModal setModalVersicherungOpen={setOpen} />}
			<form className={`Rechner ${className}`}>
				<div className="Rechner-Content">
					<div className="Rechner-HeroImage">
						<HeroImage />
					</div>
					{!internal && (
						<div className="Rechner-Awards">
							<img
								width={132}
								height={114}
								src={Awards_EC_Firmenradl_AT}
								alt="Hol dir dein Firmenradl!"
							/>
							<Image
								src="assets/Awards_EC_Firmenradl_AT.png"
								width={128}
								height={128}
								crop={false}
								// @ts-expect-error
								alt="Hol dir dein Firmenradl!"
								visibleByDefault
								fetchpriority={"high"}
							/>
						</div>
					)}
					<div className="Rechner-Slider">
						<div className="Bubbles desktop-only">
							<div className={"Bubble Big"}>
								<small>bis zu</small>
								<TextTransition
									springConfig={presets.wobbly}
									className="textTransition"
									direction={discountDirection}
								>
									{calculator.ErsparnisInEuro}
								</TextTransition>
								<small>sparen</small>
							</div>
							<div className="Bubble Small">
								<small>bis zu</small>
								<TextTransition
									springConfig={presets.wobbly}
									className="textTransition"
									direction={discountDirection}
								>
									{calculator.ErsparnisInProzent}%
								</TextTransition>
								<small>günstiger</small>
							</div>
						</div>
					</div>
					<div className="Rechner-Form">
						<h1>
							Ersparnis<span className="outline">Rechner</span>
						</h1>

						<div className="row">
							<div className="col">
								<p className="subline">
									Mit FIRMENRADL kannst du dir ein Fahrrad deiner Wahl stark
									vergünstigt holen, rechne dir gleich jetzt die Ersparnis aus
									und radel los!
								</p>
							</div>
						</div>

						<div className="row main-row">
							<div className="col Tooltip">
								<label htmlFor="fahrradPreis">Fahrrad-Preis</label>
								<CurrencyInput
									id="fahrradPreis"
									name="fahrradPreis"
									placeholder="Preis in EUR"
									defaultValue={form.price}
									decimalsLimit={2}
									intlConfig={{ locale: "de-DE", currency: "EUR" }}
									className="currency"
									onChange={(e) => {
										setForm((form) => ({
											...form,
											price: Number.parseFloat(parseNumber(e.target.value)),
										}));
										parseNumber(e.target.value);
									}}
								/>
								<Tooltip>
									Gib hier bitte den Fahrrad-Preis inkl. Zubehör und Steuer ein
								</Tooltip>
							</div>

							<div className="col Tooltip">
								<label htmlFor="einkommen">Bruttogehalt / Monat</label>
								<CurrencyInput
									id="einkommen"
									name="einkommen"
									placeholder="Einkommen in EUR"
									defaultValue={form.einkommen}
									decimalsLimit={2}
									intlConfig={{ locale: "de-DE", currency: "EUR" }}
									className="currency"
									onChange={(e) =>
										setForm((form) => ({
											...form,
											einkommen: Number.parseFloat(parseNumber(e.target.value)),
										}))
									}
								/>
								<Tooltip>
									Gib hier bitte dein tatsächliches Bruttogehalt pro Monat ein
								</Tooltip>
							</div>

							<div className="col radioGroupContainer Tooltip">
								<label>Laufzeit in Monaten</label>
								<div className="radioGroup">
									{leasingDurations.map((i) => (
										<Tab
											key={i.Id}
											id={i.Id}
											value={form.leasingDuration}
											name="laufzeit"
											// @ts-expect-error
											optionChange={(e) =>
												setForm((form) => ({
													...form,
													leasingDuration: e.target.value,
												}))
											}
											label={i.Id}
										/>
									))}
								</div>
								<Tooltip>
									Wähle hier bitte deine gewünschte Laufzeit.
									<br />
									Vergewissere dich aber auch ob in
									<br />
									deinem Unternehmen die Laufzeit möglich ist.
								</Tooltip>
							</div>
						</div>
						{error ? (
							<div className="row">
								<div className="col">
									<span className="text-center">{error}</span>
								</div>
							</div>
						) : null}

						{!advancedMode && (
							<div
								className="extensionToggle"
								onClick={() => setAdvancedMode(!advancedMode)}
								onKeyDown={(e) =>
									e.key === "escape" && setAdvancedMode(!advancedMode)
								}
							>
								<img
									src={icon_arrow_down}
									alt="Weitere Optionen anzeigen"
									className={advancedMode ? "rotated" : ""}
								/>
								Erweiterte Berechnung anzeigen
								<img
									src={icon_arrow_down}
									alt="Weitere Optionen anzeigen"
									className={advancedMode ? "rotated" : ""}
								/>
							</div>
						)}

						{advancedMode && (
							<div className={"extendedOptions"}>
								<div className="radioGroupContainer Tooltip">
									<Tooltip>
										Wähle hier dein gewünschtes Versicherungspaket.
										<br />
										Die Inhalte findest du unter weitere Infos.
										<br />
										Vergewissere dich auch hier wie die Vorgaben in deinem
										Unternehmen sind.
									</Tooltip>
									<div className="labelContainer">
										<label>Versicherungspaket</label>
										<button onClick={() => setOpen(true)} type="button">
											Weitere Infos
										</button>
									</div>
									<div className="radioGroup">
										{insurances.map((i) => (
											<Tab
												key={i.Id}
												id={i.Id}
												value={form.insuranceType}
												name="versicherung"
												// @ts-expect-error
												optionChange={(e) =>
													setForm((form) => ({
														...form,
														insuranceType: e.target.value,
													}))
												}
												label={i.Name}
											/>
										))}
									</div>
								</div>

								<div className="radioGroupContainer Tooltip">
									<div className="labelContainer">
										<label>Restwert</label>
									</div>
									<div className="radioGroup">
										{residualValues.map((i) => (
											<Tab
												key={i.Id}
												id={i.Id}
												value={form.residualValue}
												name="valueWVO"
												// @ts-expect-error
												optionChange={(e) =>
													setForm((form) => ({
														...form,
														residualValue: e.target.value,
													}))
												}
												label={i.Description}
											/>
										))}
									</div>
									{form.residualValue !== "2" ? (
										<div className="infoText">
											<p>
												Wenn der Firmenradl-Restwert geringer ist als der um
												übliche Preisnachlässe verminderte Endpreis des
												Abgabeortes, kommt es zu einer Steuerpflicht iHv EUR{" "}
												{calculator.SteuerpflichtBetrag.toFixed(2).replace(
													".",
													",",
												)}
												.
											</p>
										</div>
									) : (
										<></>
									)}
								</div>

								<div className="checkboxGroupSet">
									<div className="checkboxGroupContainer Tooltip">
										<label htmlFor="vorsteuerabzug">
											<input
												name="vorsteuerabzug"
												type="checkbox"
												checked={form.vorsteuerabzug}
												onChange={(e) =>
													setForm((form) => ({
														...form,
														vorsteuerabzug: !form.vorsteuerabzug,
													}))
												}
											/>
											Mein Arbeitgeber ist vorsteuerabzugsberechtigt
											{/*Ist das Unternehmen vorsteuerabzugsberechtigt?*/}
										</label>
										<Tooltip>
											Nutze diese Checkbox, wenn dein Arbeitgeber
											vorsteuerabzugsberechtigt ist,
											<br />
											und ihr im Unternehmen den Abzug auch geltend macht.
										</Tooltip>
									</div>

									<div className="checkboxGroupContainer">
										<label htmlFor="pendlerpauschaleActive">
											<input
												name="pendlerpauschaleActive"
												type="checkbox"
												checked={form.pendlerpauschaleActive}
												onChange={(e) =>
													setForm((form) => ({
														...form,
														pendlerpauschaleActive:
															!form.pendlerpauschaleActive,
													}))
												}
											/>
											Ich beziehe die Pendlerpauschale{" "}
											<span
												className={form.pendlerpauschaleActive ? "" : "hidden"}
											>
												für
												<input
													id="pendlerpauschale"
													name="pendlerpauschale"
													placeholder="50"
													defaultValue={form.pendlerpauschale}
													onChange={(e) =>
														setForm((form) => ({
															...form,
															pendlerpauschale: parseNumber(e.target.value),
														}))
													}
												/>{" "}
												km pro Strecke.
											</span>
										</label>
									</div>

									<div className="checkboxGroupContainer">
										<label htmlFor="familienbonusActive">
											<input
												name="familienbonusActive"
												type="checkbox"
												checked={form.familienbonusActive}
												onChange={(e) =>
													setForm((form) => ({
														...form,
														familienbonusActive: !form.familienbonusActive,
													}))
												}
											/>
											Ich beziehe einen Familienbonus
										</label>
									</div>

									{form.familienbonusActive && (
										<div>
											<div className="row indent">
												<div className="col">
													<label htmlFor="fbBis17">Kinder bis 17 Jahre</label>
													<input
														id="fbBis17"
														name="fbBis17"
														placeholder="0"
														defaultValue={form.fbBis17}
														onChange={(e) =>
															setForm((form) => ({
																...form,
																fbBis17: Number.parseInt(
																	parseNumber(e.target.value),
																),
															}))
														}
													/>
												</div>
												<div className="col">
													<label htmlFor="fbAb18">Kinder ab 18 Jahre</label>
													<input
														id="fbAb18"
														name="fbAb18"
														placeholder="0"
														defaultValue={form.fbAb18}
														onChange={(e) =>
															setForm((form) => ({
																...form,
																fbAb18: Number.parseInt(
																	parseNumber(e.target.value),
																),
															}))
														}
													/>
												</div>
											</div>
											{form.fbAb18 + form.fbBis17 < 1 && (
												<div className="row indent">
													<div
														className="col text-center"
														style={{ marginTop: 8 }}
													>
														<small>
															Mindestens 1 Kind muss angegeben werden.
														</small>
													</div>
												</div>
											)}
										</div>
									)}
								</div>
							</div>
						)}

						{!internal && (
							<div className="ButtonContainer">
								<div className="BubblesContainer mobile-only">
									<div className="Bubbles mobile-only">
										<div className="Bubble Big">
											<TextTransition
												springConfig={presets.wobbly}
												className="textTransition"
												direction={discountDirection}
											>
												{calculator.ErsparnisInEuro}
											</TextTransition>
											<small>sparen</small>
										</div>
									</div>
								</div>

								<a href={getRegisterUrl()} className="button">
									Jetzt bis zu{" "}
									<TextTransition
										springConfig={presets.wobbly}
										className="textTransition"
										direction={discountDirection}
									>
										<span key={"asdfasdf"}>
											{" "}
											{calculator.ErsparnisInProzent}
										</span>
									</TextTransition>
									% Preisvorteil sichern
								</a>
							</div>
						)}

						<div className={`row summary ${internal ? "internal" : ""}`}>
							{internal && (
								<div className="col">
									<TextTransition
										springConfig={presets.wobbly}
										className="textTransition"
										direction={discountDirection}
									>
										{calculator.ErsparnisInProzent} %
									</TextTransition>
									<small>Preisvorteil für Mitarbeiter</small>
								</div>
							)}
							<div className="col">
								<TextTransition
									springConfig={presets.wobbly}
									className="textTransition"
									direction={discountDirection}
								>
									{calculator.Restwert}
								</TextTransition>
								<small>
									Restwert (
									<TextTransition
										springConfig={presets.wobbly}
										className="textTransition"
										direction={discountDirection}
										inline
									>
										{calculator.RestwertInProzent} %
									</TextTransition>
									)
								</small>
							</div>
							{internal && (
								<div className="col">
									<TextTransition
										springConfig={presets.wobbly}
										className="textTransition"
										direction={discountDirection}
									>
										{calculator.ErsparnisInEuro}
									</TextTransition>
									<small>Ersparnis</small>
								</div>
							)}

							{internal && (
								<div className="col">
									<TextTransition
										springConfig={presets.wobbly}
										className="textTransition"
										direction={priceDirection}
									>
										{calculator.MonatlicheZahlungAG}
									</TextTransition>
									<small>Monatliche Rate vor Gehaltsumwandlung</small>
								</div>
							)}
							<div className="col Tooltip">
								<TextTransition
									springConfig={presets.wobbly}
									className="textTransition"
									direction={priceDirection}
								>
									{calculator.MonatlicheZahlung}
								</TextTransition>
								<small>Monatliche Rate nach Gehaltsumwandlung</small>
								<Tooltip>Monatliche Belastung von deinem Nettogehalt</Tooltip>
							</div>

							<div className="col Tooltip">
								<TextTransition
									springConfig={presets.wobbly}
									className="textTransition"
									direction={priceDirection}
								>
									{calculator.gesamtPreis}
								</TextTransition>

								<small>Gesamtpreis</small>
								<Tooltip>
									Gesamtsumme aus den <br />
									{form.leasingDuration} monatlichen Raten je{" "}
									{calculator.MonatlicheZahlung} + Restwert (
									{calculator.Restwert})
								</Tooltip>
							</div>
						</div>

						<small>
							Dieser Rechner bietet eine Richtlinie wie die Ersparnis mit
							FIRMENRADL aussehen könnte. Der tatsächliche Betrag hängt von den
							tatsächlichen Bezügen und Abzügen des Arbeitnehmers ab. Um eine
							100&nbsp;% korrekte Auskunft zu erhalten kontaktiere bitte deinen
							Arbeitgeber bzw. die Personalabrechnungsabteilung deines
							Unternehmens.
						</small>
					</div>
				</div>
			</form>
		</>
	);
}

function HeroImage() {
	// get window width and height for image resizing
	let width = window.innerWidth;
	let height = window.innerHeight;

	// get a larger photo for mobile
	if (width < 800) {
		width = width * 2;
	}

	// reducing width and height to a multiple of 64 to achieve less caching and resizing
	width = width - (width % 64);
	height = height - (height % 64);

	let image = null;
	if (window.innerWidth < 800) {
		image = (
			<Image
				src="assets/mood_red_hero_mobile.jpg"
				width={368}
				height={800}
				crop={true}
				// @ts-expect-error
				alt="Hol dir dein Firmenradl!"
				visibleByDefault
				fetchpriority="high"
			/>
		);
	} else {
		image = (
			<Image
				src="assets/mood_red_hero2.jpg"
				width={width}
				height={height}
				crop={false}
				// @ts-expect-error
				alt="Hol dir dein Firmenradl!"
				visibleByDefault
				fetchpriority="high"
			/>
		);
	}

	return image;
}

function Tab({
	id,
	value,
	name,
	label,
	optionChange,
}: {
	id: string | number;
	value: string | number;
	name: string;
	label: string;
	// @ts-ignore
	// biome-ignore lint/suspicious/noExplicitAny: <explanation>
	optionChange: any;
}) {
	return (
		<label className={id === value ? "checked" : "not-checked"}>
			<input
				type="radio"
				name={name} //"versicherung"
				value={id}
				checked={id === value}
				onChange={optionChange}
			/>{" "}
			{label}
		</label>
	);
}

export default Rechner;
