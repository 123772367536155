import type { IAdvantage, IStep } from "../types/types";

const advantagesPartner: IAdvantage[] = [
	{
		title: "Einfaches, schnelles Onlineportal",
		image: "assets/filler_mood_red_3.jpg",
		content:
			"Wir stellen dir die komplette Abwicklungsplattform kostenlos und top-gewartet zur Verfügung.",
	},
	{
		title: "Kundenbindung",
		image: "assets/filler_mood_blue_3.jpg",
		content:
			"Durch die Vertragslaufzeit kannst du Kunden zusätzlich an dein Unternehmen binden.",
	},
	{
		title: "Geld sofort am Konto",
		image: "assets/filler_mood_blue_2.jpg",
		content:
			"Wir gewährleisten dir eine sichere und schnelle Überweisung des Rechnungsbetrages.",
	},
	{
		title: "Großkunden gewinnen",
		image: "assets/filler_mood_red_2.jpg",
		content:
			"Mit FIRMENRADL können auch größere Unternehmen direkt angesprochen und als Kunden gewonnen werden.",
	},
	{
		title: "Werbeeffekt durch Eintrag auf firmenradl.at",
		image: "assets/filler_mood_blue_1.jpg",
		content:
			"Eine Verlinkung auf firmenradl.at unterstützt deinen Onlineauftritt und gibt dir einen Sichtbarkeits-Boost.",
	},
];

const advantagesFirm: IAdvantage[] = [
	{
		title: "Null Kosten",
		image: "assets/filler_mood_red_4.jpg",
		content:
			"Für dich als Arbeitgeber entstehen bei FIRMENRADL keine Kosten, auch nicht in der Anschaffung. Der Arbeitnehmer bezahlt das Fahrrad, indem die monatliche Leasingsumme automatisch vom Bruttogehalt abgezogen wird.",
	},
	{
		title: "Anreiz für neue Mitarbeitende",
		image: "assets/filler_mood_lime_3.jpg",
		content:
			"Heutzutage zählen bei Jobsuchenden oft auch die Benefits, die ein Arbeitgeber anbietet. Mit FIRMENRADL sicherst du dir einen zusätzlichen Vorteil im „War of Talents“.",
	},
	{
		title: "Mitarbeiterbindung",
		image: "assets/filler_mood_red_3.jpg",
		content:
			"Ein Angebot wie ein FIRMENRADL motiviert Mitarbeitende zusätzlich bei deinem Unternehmen zu arbeiten. Und auch zu bleiben. Ein netter Nebeneffekt ist, dass sportlich-aktive Mitarbeitende zudem seltener krank werden.",
	},
	{
		title: "Nachhaltigkeit",
		image: "assets/filler_mood_lime_1.jpg",
		content:
			"Mit FIRMENRADL tust du etwas Gutes für die Umwelt und reduzierst den ökologischen Fußabdruck, was sich positiv aufs Image deines Unternehmens auswirkt.",
	},
];

const advantagesUser: IAdvantage[] = [
	{
		title: "Bis zu 35% günstiger",
		image: "assets/filler_mood_blue_2.jpg",
		content:
			"<em>Leasen statt kaufen!</em> Mit FIRMENRADL fährst du bei der Gehaltsumwandlung einen Steuervorteil von bis zu 35 % ein.",
	},
	{
		title: "Versicherung & Reparaturen inkludiert",
		image: "assets/filler_mood_lime_2.jpg",
		content:
			"<em>Flitzen statt schwitzen!</em> Dein FIRMENRADL ist automatisch versichert, wodurch du sorgenfrei losradln kannst. Zudem gibt es zwei Versicherungspakete für etwaige Reparaturen.",
	},
	{
		title: "Top-Markenauswahl",
		image: "assets/filler_mood_lime_4.jpg",
		content:
			"<em>Wählen statt quälen!</em> Du bist bei FIRMENRADL an keine Marke oder Modell gebunden und genießt in ganz Österreich die volle Auswahl an Fahrrädern oder E-Bikes.",
	},
	{
		title: "Monatliche Raten",
		image: "assets/filler_mood_red_4.jpg",
		content:
			"<em>Starten statt warten!</em> Du musst keine große Summe zusammensparen und auch nicht gleich bezahlen, sondern lässt dir ganz bequem und ohne Aufwand die Monatsraten vom Gehalt abziehen. Ziemlich praktisch!",
	},
];

const stepsFirm: IStep[] = [
	{
		title: "Registrieren",
		text: "Melde dich als Arbeitgeber direkt auf firmenradl.at an und fülle alle notwendigen Verträge aus.",
	},
	{
		title: "Einrichten",
		text: "Nachdem wir deine Dokumente geprüft haben gibst du etwaige Rahmenbedingungen wie z.B. Preislimits für Bikes oder Laufzeiten ein.",
	},
	{
		title: "Freigeben",
		text: "Im nächsten Schritt erteilst du deinen Mitarbeitenden die Freigabe für die Nutzung von FIRMENRADL. Danach können sich deine Mitarbeitenden schon ihr Wunschbike holen.",
	},
	{
		title: "Verwalten",
		text: "Behalte als Arbeitgeber im Onlineportal jederzeit den Überblick über alle laufenden Kosten und Abschlüsse.",
	},
];

const stepsUser: IStep[] = [
	{
		title: "Motivieren",
		text: "Überzeuge deinen Arbeitgeber von den vielen Partner-Vorteilen.",
	},
	{
		title: "Registrieren",
		text: "Melde dich direkt auf firmenradl.at an.",
	},
	{
		title: "Selektieren",
		text: "Such dir dein Wunschbike bei einem Händler deiner Wahl aus.",
	},
	{
		title: "Pilotieren",
		text: "Starte mit deinem neuen Bike los – in die Arbeit oder privat!",
	},
];

export {
	stepsUser,
	stepsFirm,
	advantagesUser,
	advantagesPartner,
	advantagesFirm,
};
