import React from "react";
import "./Brand.scss";
import { getBaseUrl } from "../helpers/getBaseUrl";
import Image from "./Image";

type Props = {
	name: string;
	logo: string;
	id: string | number;
	open?: undefined;
	imageVisibleByDefault?: boolean;
};

function Brand({ name, logo, id, open, imageVisibleByDefault = false }: Props) {
	let img = <div />;

	if (logo.indexOf(".svg") > 0) {
		const baseUrl = getBaseUrl(true, "/logo/"); // image is an svg
		img = <img src={`${baseUrl}${logo}`} alt={name} width={150} height={150} />;
	} else if (logo.length > 5) {
		img = (
			<Image
				src={logo}
				width={150}
				height={150}
				// @ts-expect-error
				alt={name}
				crop={false}
				fetchpriority="low"
				logoUrl
				visibleByDefault={imageVisibleByDefault}
			/>
		);
	}

	return (
		<button
			className="Brand"
			// @ts-expect-error
			href={`#${id}`}
			// @ts-expect-error
			onClick={() => open(id)}
			type={"button"}
		>
			<div className="Brand-image">{img}</div>
			<div className="Brand-text">
				<h3>{name}</h3>
			</div>
		</button>
	);
}

export default Brand;
