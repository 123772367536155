import { random } from "lodash";
import type { IBrand } from "../types/types";

function generateRandomBrands(allBrands: { [p: string]: IBrand }) {
	const randomBrandsToAdd = [];
	const randomBrandsToAddIds: number[] = [];
	const selectedMarkenIds: number[] = [];

	// biome-ignore lint/complexity/noForEach: <explanation>
	Object.keys(allBrands).forEach((key) => {
		return randomBrandsToAddIds.push(Number(key));
	});

	// limited set
	for (let j = 0; j < 12; j++) {
		let newIndex: number;
		let newId: number;

		do {
			// check for duplicate brands, get a new brand in case duplicates are found
			newIndex = random(randomBrandsToAddIds.length - 1);
			newId = randomBrandsToAddIds[newIndex];
		} while (selectedMarkenIds.indexOf(newId) >= 0);

		selectedMarkenIds.push(newId);
		randomBrandsToAdd.push(allBrands[newId]);
	}

	return randomBrandsToAdd;
}

export default generateRandomBrands;
