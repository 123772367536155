import React, { Suspense, useRef } from "react";
import { Helmet } from "react-helmet";
// @ts-expect-error
import portraitAlexanderBreitwieser from "../assets/kontakt/Alexander_Breitwieser_480x640_2024.jpg";
// @ts-expect-error
import portraitMarkusHaefensauer from "../assets/kontakt/Häfensauer_Markus_480x640_2024.jpg";
// @ts-expect-error
import portraitNorbertNeschwara from "../assets/kontakt/Norbert_Neschwara_480x640.jpg";
import Spacer from "../components/Spacer";
import useIntersectionObserver from "../helpers/useInteractionObserver";
import "./KontaktPage.scss";
import ETypeOfPartnership from "../types/enum/type-of-partnerships";

const MultiMap = React.lazy(() => import("../components/map/MultiMap"));

const KontaktPage = () => {
	const mapsSection = useRef(null);
	const isMapsSectionVisible = useIntersectionObserver(mapsSection);

	return (
		<div>
			<Helmet>
				<title>Radl-Händler & Bike-Fachhändler | FIRMENRADL</title>
				{/* TODO: no copy */}
				<meta name="description" content="Kontaktiere uns!" />
				<meta name="theme-color" content="#474395" />

				<meta property="og:title" content="Kontakt | FIRMENRADL Bike-Leasing" />
				<meta
					property="og:image"
					content="img/assets/mood_blue_full.jpg?w=512"
				/>
				{/* TODO: no copy */}
				<meta property="og:description" content="Kontaktiere uns!" />
			</Helmet>

			<section className="kontakt-section">
				<h1>
					Für allgemeine Fragen wende dich bitte an{" "}
					<a href="mailto:office@firmenradl.at">office@firmenradl.at</a> oder
					telefonisch an <a href="tel:+43800201909">0800 201909</a>!
				</h1>
			</section>

			<Spacer />

			<section className="kontakt-section">
				<h2>Außendienstmitarbeiter</h2>
				<div className="kontakt-grid">
					<KontaktCard
						image={portraitMarkusHaefensauer}
						name="Markus Häfensauer"
						phoneNumber="+43 664 88850650"
						location="Salzburg / Kärnten / Tirol und Vorarlberg"
						email="Markus.Haefensauer@intersport.at"
					/>
					<KontaktCard
						image={portraitNorbertNeschwara}
						name="Norbert Neschwara"
						phoneNumber="+43 664 88850559"
						location="Wien / Niederösterreich und Burgenland"
						email="Norbert.Neschwara@intersport.at"
					/>
					<KontaktCard
						image={portraitAlexanderBreitwieser}
						name="Alexander Breitwieser"
						phoneNumber="+43 664 88850624"
						location="Oberösterreich und Steiermark"
						email="Alexander.Breitwieser@intersport.at"
					/>
				</div>
			</section>
			<Spacer />
			<section className="suspenseSection multiMapSection" ref={mapsSection}>
				{isMapsSectionVisible && (
					<Suspense
						fallback={<div className="suspenseFallback">Loading...</div>}
					>
						<MultiMap type={ETypeOfPartnership.all} hideBanner={true} />
					</Suspense>
				)}
			</section>
		</div>
	);
};

export default KontaktPage;

type Props = {
	image: string;
	name: string;
	phoneNumber: string;
	location: string;
	email: string;
};

function KontaktCard({ image, name, phoneNumber, location, email }: Props) {
	return (
		<div className="kontakt-card">
			<img src={image} alt={`Portrait ${name}`} />
			<div>
				<h3>{name}</h3>
				<p>
					<a href={`tel:${phoneNumber}`}>{phoneNumber}</a> <br />
					{location} <br />
					<a href={`mailto:${email}`}>{email}</a>
				</p>
			</div>
		</div>
	);
}
