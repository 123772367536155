import React, { StrictMode } from "react";
import "./index.scss";
import { createRoot } from "react-dom/client";
import { RouterProvider } from "react-router-dom";
import router from "./router";

const container = document.getElementById("root");
// biome-ignore lint/style/noNonNullAssertion: <explanation>
const root = createRoot(container!);
root.render(
	<StrictMode>
		<RouterProvider router={router} />
	</StrictMode>,
);
