import React from "react";
import "./styles/MapMarkerPopup.scss";
import Image from "../Image";

// @ts-expect-error
import social_fb from "../../assets/social_fb.svg";
// @ts-expect-error
import social_ig from "../../assets/social_ig.svg";
// @ts-expect-error
import social_li from "../../assets/social_li.svg";
import type { IFirm, IPartner } from "../../types/types";

const Content = ({ marker }: { marker: IFirm | IPartner }) => {
	let brands = null;

	if (Array.isArray(marker.bikes) && marker.bikes.length) {
		brands = (
			<div>
				<b>Marken</b>
				<ul className="brands">
					{marker.bikes.map((bike) => (
						<li key={`bike_brand_${bike.id}`}>{bike.name}</li>
					))}
				</ul>
			</div>
		);
	}

	const socialMedia = [];

	let homepageUrl: string;
	if (marker.homepage) {
		if (marker.homepage.indexOf("http") < 0) {
			homepageUrl = `http://${marker.homepage}`;
		} else {
			homepageUrl = marker.homepage;
		}
		socialMedia.push(
			<a
				target="_blank"
				rel="noreferrer"
				href={homepageUrl}
				className={"website"}
				key={`social_web_${marker.id}`}
			>
				Webseite
			</a>,
		);
	}

	if (marker.instagram) {
		socialMedia.push(
			<a
				key={`social_instagram_${marker.id}`}
				target="_blank"
				rel="noreferrer"
				href={marker.instagram}
			>
				<img src={social_ig} width={24} height={24} alt="instagram" />
			</a>,
		);
	}

	if (marker.facebook) {
		socialMedia.push(
			<a
				key={`social_facebook_${marker.id}`}
				target="_blank"
				rel="noreferrer"
				href={marker.facebook}
			>
				<img src={social_fb} width={24} height={24} alt="facebook" />
			</a>,
		);
	}

	if (marker.linkedin) {
		socialMedia.push(
			<a
				key={`social_linkedin_${marker.id}`}
				target="_blank"
				rel="noreferrer"
				href={marker.linkedin}
			>
				<img src={social_li} width={24} height={24} alt="linked in" />
			</a>,
		);
	}

	return (
		<div className="partner">
			<div className="header">
				<div className="text">
					<b>{marker.title}</b>
					<br />
					<div className="address">
						{marker.street} <br />
						{marker.zip} {marker.city}
					</div>
				</div>
				<div className="image">
					<Image
						src={marker.logo}
						height={200}
						width={200}
						crop={false}
						logoUrl
					/>
				</div>
			</div>
			<div className="footer">
				{brands}
				{socialMedia.length ? (
					<div className={"socials"}>{socialMedia}</div>
				) : null}
			</div>
		</div>
	);
};

function MapMarkerPopup({
	firms,
	partners,
}: { firms: IFirm[]; partners: IPartner[] }) {
	return (
		<div className="MapMarkerPopup">
			{partners.length ? (
				<div className="group">
					<h3>Radl-Händler</h3>
					{partners.map((partner) => (
						<Content marker={partner} key={`partner_${partner.id}`} />
					))}
				</div>
			) : (
				<></>
			)}

			{firms.length ? (
				<div className="group">
					<h3 className="blue">Arbeitgeber</h3>
					{firms.map((firm: IFirm) => (
						<Content marker={firm} key={`firm_${firm.id}`} />
					))}
				</div>
			) : (
				<></>
			)}
		</div>
	);
}

export default MapMarkerPopup;
