import {
	GoogleMap,
	InfoWindow,
	Marker,
	useLoadScript,
} from "@react-google-maps/api";
import React, { memo, useEffect, useState } from "react";
import useMapStore from "../../hooks/useMapStore";
import type { IMarkerDaniel } from "../../types/types";
import MapMarkerPopup from "./MapMarkerPopup";
import { GOOGLE_LIBRARIES, GOOGLE_MAPS_API_KEY } from "./MultiMap";

const containerStyle = {
	width: "100%",
	height: "100%",
};

let userInputTimeout = false;

type Props = {
	initialMarkers: IMarkerDaniel[];
};

const MapFirmenradl = ({ initialMarkers }: Props) => {
	const { isLoaded } = useLoadScript({
		googleMapsApiKey: GOOGLE_MAPS_API_KEY,
		libraries: GOOGLE_LIBRARIES,
	});
	const { center, setCenter, zoom, setZoom, activeWindow, setActiveWindow } =
		useMapStore();

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [map, setMap] = useState<google.maps.Map | null>(null);

	useEffect(() => {
		if (map) {
			map.setCenter(center);
			map.setZoom(zoom);
		}
	}, [center, map, zoom]);

	const onLoad = (map: google.maps.Map | null) => {
		setMap(map);
	};

	const handleActiveMarker = (marker: IMarkerDaniel) => {
		setActiveWindow(marker.id);
		setCenter(marker.location);
		setZoom(14);
	};

	const markerDragEnd = () => {};

	const onMapMoved = () => {
		if (userInputTimeout) {
			// @ts-expect-error
			clearTimeout(userInputTimeout);
		}

		if (map) {
			// @ts-expect-error
			userInputTimeout = setTimeout(() => {
				const newCenter = map.getCenter();
				if (center.lat !== newCenter?.lat()) {
					// @ts-expect-error
					setCenter({ lat: newCenter.lat(), lng: newCenter.lng() });
				}

				if (zoom !== map.getZoom()) {
					// @ts-expect-error
					setZoom(map.getZoom());
				}
				userInputTimeout = false;
			}, 500);
		}
	};

	return isLoaded ? (
		<GoogleMap
			center={center}
			zoom={zoom}
			onCenterChanged={onMapMoved}
			onZoomChanged={onMapMoved}
			onLoad={onLoad}
			mapContainerStyle={containerStyle}
		>
			{initialMarkers.map((marker) => (
				<Marker
					key={marker.id}
					position={marker.location}
					icon={marker.icon?.url}
					onClick={() => handleActiveMarker(marker)}
					onDragEnd={() => markerDragEnd()}
				>
					{activeWindow === marker.id ? (
						<InfoWindow
							key={marker.id}
							position={marker.location}
							onCloseClick={() => setActiveWindow("")}
						>
							<MapMarkerPopup firms={marker.firms} partners={marker.partners} />
						</InfoWindow>
					) : (
						<></>
					)}
				</Marker>
			))}
		</GoogleMap>
	) : (
		<></>
	);
};

export default memo(MapFirmenradl);
