import React from "react";
// @ts-expect-error
import icon_close from "../assets/icon_close.svg";

type Props = {
	setModalVersicherungOpen: (b: boolean) => void;
};

function RechnerModal(props: Props) {
	const { setModalVersicherungOpen } = props;
	return (
		<div className="Rechner-Modal">
			<div
				className="Rechner-Modal-Closer"
				onClick={() => setModalVersicherungOpen(false)}
				onKeyDown={(e) => e.key === "escape" && setModalVersicherungOpen(false)}
			/>
			<div className="Rechner-Modal-Content">
				<button
					className="Rechner-Modal-Close"
					onClick={() => setModalVersicherungOpen(false)}
					onKeyDown={(e) =>
						e.key === "escape" && setModalVersicherungOpen(false)
					}
					type="button"
				>
					<img
						src={icon_close}
						width={24}
						height={24}
						alt="Fenster Schliessen"
					/>
				</button>
				<div className="flex flex-col">
					<h2>LEISTUNGEN IM ÜBERBLICK</h2>
					<h3>(§§2,3 AVB) FIRMENRADL VERSICHERUNG Stand 09/2024</h3>
				</div>
				<div className="row">
					{/* FIRMENRADL */}
					<ContentFirmenradl />

					{/* FIRMENRADL INSPEKTION */}
					<ContentFirmenradlInspektion />
				</div>
				<div className="row">
					{/* FIRMENRADL AUSFALLSCHUTZ */}
					<ContentAusfallschutz />
				</div>
			</div>
		</div>
	);
}

export default RechnerModal;

function ContentFirmenradl() {
	return (
		<div className="col">
			<h3 className="elment">FIRMENRADL VERSICHERUNG</h3>

			<h4 className="block-red">REPARATURKOSTEN</h4>
			<p className="elment">
				<ul>
					<li>Fall-/Sturzschäden</li>
					<li>Unfallschäden</li>
					<li>Unsachgemäße Handhabung</li>
					<li>Vandalismus</li>
					<li>Feuchtigkeit</li>
					<li>Elektronikschäden</li>
					<li>
						<em>Verschleiß</em>
					</li>
					<li>KEIN LIMIT & OHNE DECKELUNG</li>
				</ul>
			</p>

			{/* --- */}
			<h4 className="block-red">TOTALSCHADEN</h4>
			<p className="elment">
				Vereinbarte Ablösesumme aus dem zugrundeliegenden Leasingvertrag,
				maximal jedoch die Versicherungssumme.
			</p>

			{/* --- */}
			<h4 className="block-red">DIEBSTAHL</h4>
			<p className="elment">
				Vereinbarte Ablösesumme aus dem zugrundeliegenden Leasingvertrag,
				maximal jedoch die Versicherungssumme.
			</p>

			{/* --- */}
			<h4 className="block-red">INSPEKTION</h4>
			<p className="elment" style={{ height: "64px" }} />

			{/* --- */}
			<h4 className="block-red">PICK UP SERVICE</h4>
			<p className="elment">
				Kostenübernahme bei Ausfall des FIRMENRADL während einer Ausfahrt durch:
				<ul>
					<li>Beschädigung oder Diebstahl des FIRMENRADL</li>
					<li>
						Ausfall des Motors/der Motorunterstützung auf Grund eines Defekts
					</li>
					<li>Mechanischer Mangel durch Ketten oder Rahmenbruch</li>
					<li>Reifenpanne</li>
					<li>Unfall/Sturz</li>
				</ul>
			</p>

			{/* --- */}
			<h4 className="block-red">AKKUSCHUTZ</h4>
			<p className="elment">
				<ul>
					<li>Verschleiß, Abnutzung, Alterung</li>
					<li>Unsachgemäße Handhabung</li>
					<li>Feuchtigkeit</li>
					<li>Elektronikschaden</li>
				</ul>
			</p>
		</div>
	);
}

function ContentFirmenradlInspektion() {
	return (
		<div className="col">
			<h3 className="elment">
				FIRMENRADL <br /> INSPEKTION
			</h3>

			{/* --- */}
			<h4 className="block-red">REPARATURKOSTEN</h4>
			<p className="elment">
				<ul>
					<li>Fall-/Sturzschäden</li>
					<li>Unfallschäden</li>
					<li>Unsachgemäße Handhabung</li>
					<li>Vandalismus</li>
					<li>Feuchtigkeit</li>
					<li>Elektronikschäden</li>
					<li>
						<em>Verschleiß</em>
					</li>
					<li>KEIN LIMIT & OHNE DECKELUNG</li>
				</ul>
			</p>

			{/* --- */}
			<h4 className="block-red">TOTALSCHADEN</h4>
			<p className="elment">
				Vereinbarte Ablösesumme aus dem zugrundeliegenden Leasingvertrag,
				maximal jedoch die Versicherungssumme.
			</p>

			{/* --- */}
			<h4 className="block-red">DIEBSTAHL</h4>
			<p className="elment">
				Vereinbarte Ablösesumme aus dem zugrundeliegenden Leasingvertrag,
				maximal jedoch die Versicherungssumme.
			</p>

			{/* --- */}
			<h4 className="block-red">INSPEKTION</h4>
			<p className="elment">Eine jährliche Inspektion (bis maximal € 100,-)</p>

			{/* --- */}
			<h4 className="block-red">PICK UP SERVICE</h4>
			<p className="elment">
				Kostenübernahme bei Ausfall des FIRMENRADL während einer Ausfahrt durch:
				<ul>
					<li>Beschädigung oder Diebstahl des FIRMENRADL</li>
					<li>
						Ausfall des Motors/der Motorunterstützung auf Grund eines Defekts
					</li>
					<li>Mechanischer Mangel durch Ketten oder Rahmenbruch</li>
					<li>Reifenpanne</li>
					<li>Unfall/Sturz</li>
				</ul>
			</p>

			{/* --- */}
			<h4 className="block-red">AKKUSCHUTZ</h4>
			<p className="elment">
				<ul>
					<li>Verschleiß, Abnutzung, Alterung</li>
					<li>Unsachgemäße Handhabung</li>
					<li>Feuchtigkeit</li>
					<li>Elektronikschaden</li>
				</ul>
			</p>
		</div>
	);
}

function ContentAusfallschutz() {
	return (
		<div style={{ marginTop: "1.5rem" }} className="col">
			<h3 className="block-red">AUSFALLSCHUTZ FÜR ARBEITGEBER</h3>
			<div className="elment">
				<h4>Austritt aus dem Unternehmen</h4>
				<p>
					Variante 1: Mitarbeiter kauft das FIRMENRADL aus dem Vertrag <br />
					Variante 2: Neuer Arbeitgeber übernimmt den Vertrag (inklusive
					Bearbeitungsgebühr) <br /> Variante 3: Anderer Mitarbeiter übernimmt
					den Vertrag <br /> Variante 4: Mitarbeiter gibt das FIRMENRADL
					inklusive Zubehör bei einem Fachhändler retour <br /> Variante 5:
					Arbeitgeber übernimmt das FIRMENRADL
				</p>
			</div>

			{/* --- */}
			<div className="elment">
				<h4>Ableben</h4>
				<p>
					Variante 1: anderer Mitarbeiter übernimmt das FIRMENRADL <br />
					Variante 2: FIRMENRADL wird an einen Fachhändler retour gegeben
				</p>
			</div>

			{/* --- */}
			<div className="elment">
				<h4>Erwerbsunfähigkeit</h4>
				<p>Gesetzliche Entgeltfortzahlung ist abzuwarten</p>
			</div>

			{/* --- */}
			<div className="elment">
				<h4>Krankenstand</h4>
				<p>Raten werden übernommen sobald die volle Lohnfortzahlung entfällt</p>
			</div>

			{/* --- */}
			<div className="elment">
				<h4>Mutterschutz / Elternkarenz / Papamonat</h4>
				<p>Raten werden übernommen bis der Mitarbeiter wieder zurück ist</p>
			</div>

			{/* --- */}
			<div className="elment">
				<h4>Bildungskarenz</h4>
				<p>maximal 4 Monate</p>
			</div>

			{/* --- */}
			<div className="elment">
				<h4>Saisonarbeitskräfte</h4>
				<p>maximal 4 Monate</p>
			</div>

			{/* --- */}
			<div className="elment">
				<h4>Kurzarbeit</h4>
				<p>maximal 4 Monate</p>
			</div>

			{/* --- */}
			<div className="elment">
				<h4>Wiedereinberufung / Truppenübung</h4>
				<p>volle Ratenübernahme</p>
			</div>

			{/* --- */}
			<div className="elment">
				<h4>Wiedereingliederungsteilzeit</h4>
				<p>Zur Erleichterung der Rückkehr zur Arbeit nach langer Krankheit</p>
			</div>

			{/* --- */}
			<div className="elment">
				<h4>Ausschlüsse</h4>
				<p>
					<ul>
						<li>Unterschreitung von kollektivlichem Mindestlohn</li>
						<li>Sabbatical</li>
						<li>unbezahlter Urlaub / Freistellung</li>
						<li>Pfändung</li>
						<li>Krankenstand bei voller Entgeltfortzahlung</li>
						<li>Präsenzdienst /Zivildienst</li>
					</ul>
				</p>
			</div>
		</div>
	);
}
