// @ts-nocheck
import React from "react";
import { Helmet } from "react-helmet";

const HilfeHaendlerPage = () => (
	<div className={"HilfeHaendler Rechtliches"}>
		<Helmet>
			<title>Hilfebereich für Händler | FIRMENRADL</title>
			<meta
				name="description"
				content="Wir sind FIRMENRADL und bieten Arbeitnehmern und Arbeitgebern eine neue, innovative Art der Mobilität."
			/>
			<meta name="robots" content="noindex, nofollow" />
			<meta name="theme-color" content="#E0334C" />
		</Helmet>
		<div className="fr-view u-clearfix u-rich-text u-text u-text-3">
			<h1>Hilfebereich für Händler</h1>
			<h2>Videobeschreibung für die Auftragsanlage im Firmenradl Portal:</h2>
			<video className="embed-responsive-item" controls>
				<source
					src="https://firmenradl.at/start/videos/haendlerrechnungen.mp4"
					type="video/mp4"
				/>
				<p>Your browser does not support HTML5 video.</p>
			</video>

			<h2>
				Videobeschreibung für die Anlage eine Schadensfalles im Firmenradl
				Portal:
			</h2>
			<video className="embed-responsive-item" controls>
				<source
					src="https://firmenradl.at/start/videos/servicefall.mp4"
					type="video/mp4"
				/>
				<p>Your browser does not support HTML5 video.</p>
			</video>

			<p>
				Bei weiteren Fragen wendet euch bitte an{" "}
				<a href="mailto:office@firmenradl.at">office@firmenradl.at</a>
			</p>
		</div>
	</div>
);

export default HilfeHaendlerPage;
