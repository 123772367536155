async function apiCall<T>(
	endpoint: string | URL | globalThis.Request,
	// biome-ignore lint/suspicious/noExplicitAny: <explanation>
	init?: any,
): Promise<T> {
	try {
		const response = await fetch(endpoint, init);
		if (!response.ok) {
			throw new Error(`Network response was not ok ${response.statusText}`);
		}
		return await response.json();
	} catch (error) {
		// @ts-ignore
		console.error("Fetch error:", error); // Handling any errors
		// @ts-ignore
		return error;
	}
}

function apiCMS<T>(base: string | URL | globalThis.Request): Promise<T> {
	return fetch(base)
		.then((res) => res.json())
		.catch((error) => {
			console.error("An error occurred:", error);
		});
}

export { apiCall, apiCMS };
