import React, { Suspense, useRef } from "react";
import { Helmet } from "react-helmet";
import Advantages from "../components/Advantages";
import FAQ from "../components/FAQ";
import Hero from "../components/Hero";
import Spacer from "../components/Spacer";
import Steps from "../components/Steps";
import { advantagesFirm, stepsFirm } from "../data/static_data";
import { getRegisterUrl } from "../helpers/getRegisterUrl";
import useIntersectionObserver from "../helpers/useInteractionObserver";
import ETypeOfPartnership from "../types/enum/type-of-partnerships";

const MultiMap = React.lazy(() => import("../components/map/MultiMap"));

const FirmsPage = () => {
	const mapsSection = useRef(null);
	const isMapsSectionVisible = useIntersectionObserver(mapsSection);

	return (
		<div>
			<Helmet>
				<title>Arbeitgeber & Partner | FIRMENRADL Bike-Leasing</title>
				<meta
					name="description"
					content="Partner werden ist abgefahren! Eröffne deinen Mitarbeitenden die Chance, ein FIRMENRADL zu abgefahren günstigen Konditionen zu bekommen."
				/>
				<meta name="theme-color" content="#474395" />

				<meta
					property="og:title"
					content="Arbeitgeber & Partner | FIRMENRADL"
				/>
				<meta
					property="og:image"
					content="img/assets/mood_blue_full.jpg?w=512"
				/>
				<meta
					property="og:description"
					content="Partner werden ist abgefahren! Eröffne deinen Mitarbeitenden die Chance, ein FIRMENRADL zu abgefahren günstigen Konditionen zu bekommen."
				/>
			</Helmet>
			<Hero
				headline="PARTNER WERDEN IST ABGEFAHREN!"
				text="<p><em>Du</em> bist Unternehmer und willst für frischen Schwung im Team sorgen? Dann eröffne deinen Mitarbeitenden die Chance, ein FIRMENRADL zu abgefahren günstigen Konditionen zu bekommen.</p>"
				image="assets/mood_blue_crop.jpg"
				imageMobile="assets/mood_blue_full.jpg"
				buttonVisible
				buttonText={"Keine Zeit verlieren und gleich registrieren!"}
				buttonLink={getRegisterUrl()}
			/>
			<Steps steps={stepsFirm} />
			<Advantages location="firms" advantages={advantagesFirm} />
			<Spacer />
			<section className="suspenseSection multiMapSection" ref={mapsSection}>
				{isMapsSectionVisible && (
					<Suspense
						fallback={<div className="suspenseFallback">Loading...</div>}
					>
						<MultiMap hideBanner={true} type={ETypeOfPartnership.arbeitgeber} />
					</Suspense>
				)}
			</section>
			<FAQ type="firms" />
		</div>
	);
};

export default FirmsPage;
