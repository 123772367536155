export function getBaseUrl(
	full = true,
	subDir = "",
	nav = false,
	forceFull = false,
	image = false,
) {
	// const appendix = process.env.NODE_ENV === "development" ? "/" : "/cms/";
	const appendix = "/cms/";
	let usedSubDir = subDir;

	// console.log(subDir.)
	if (usedSubDir.startsWith("/", 0)) {
		usedSubDir = usedSubDir.substring(1);
	}

	let serverUrl =
		process.env.NODE_ENV === "development" ||
		navigator.userAgent === "ReactSnap"
			? "http://localhost:8080"
			: "https://firmenradl.at";

	if (window.location.hostname === "test-firmenradl.intersport.at") {
		serverUrl = "https://test-firmenradl.intersport.at";
	}

	if (image) {
		serverUrl = "https://firmenradl.at";
	}

	if (full) {
		if (navigator.userAgent === "ReactSnap" && forceFull === false) {
			return appendix + usedSubDir;
		}
		return serverUrl + appendix + usedSubDir;
	}
	if (nav) {
		return "/cms";
	}

	return appendix + usedSubDir;
}
