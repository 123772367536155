import React from "react";
import { Helmet } from "react-helmet";
import Brands from "../components/Brands";
import Spacer from "../components/Spacer";

function BrandsPage() {
	return (
		<div>
			<Helmet>
				<title>Marken-Bikes | FIRMENRADL</title>
				<meta
					name="description"
					content="Du bekommst dein gewünschtes Marken-Fahrrad, egal ob normales Radl oder E-Bike, bequem im Leasing direkt über deinen Arbeitgeber mittels Gehaltsabzug."
				/>
			</Helmet>
			<Spacer />
			<Brands showAll />
		</div>
	);
}

export default BrandsPage;
