import React from "react";
import { Helmet } from "react-helmet";
import Spacer from "../components/Spacer";
import { useCachedData } from "../hooks/useCachedData";

function DownloadPage() {
	const { files } = useCachedData();
	return (
		<div>
			<Helmet>
				<title>Downloads | FIRMENRADL</title>
				<meta
					name="description"
					content="Du bekommst dein gewünschtes Marken-Fahrrad, egal ob normales Radl oder E-Bike, bequem im Leasing direkt über deinen Arbeitgeber mittels Gehaltsabzug."
				/>
			</Helmet>
			<Spacer />
			<div>
				<h1>Firmenradl Downloads</h1>
				{files && (
					<ul>
						{Object.keys(files).map((file) => (
							<li key={file}>
								<a href={`download/${files[file]}`}>{files[file]}</a>
							</li>
						))}
					</ul>
				)}
			</div>
		</div>
	);
}

export default DownloadPage;
