import React, { Suspense, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import Advantages from "../components/Advantages";
import Brands from "../components/Brands";
import FAQ from "../components/FAQ";
import Rechner from "../components/Rechner";
import Steps from "../components/Steps";
import TextContent from "../components/TextContent";
import MapPopup from "../components/map/MapPopup";
import { advantagesUser, stepsUser } from "../data/static_data";
import { getRegisterUrl } from "../helpers/getRegisterUrl";
import useIntersectionObserver from "../helpers/useInteractionObserver";
import useSize from "../hooks/useSize";
import ETypeOfPartnership from "../types/enum/type-of-partnerships";

const MultiMap = React.lazy(() => import("../components/map/MultiMap"));
const Video = React.lazy(() => import("../components/Video"));
const Reviews = React.lazy(() => import("../components/Reviews"));

function HomePage() {
	const windowSize = useSize();
	const mapsSection = useRef(null);
	const isMapsSectionVisible = useIntersectionObserver(mapsSection);

	const videoSection = useRef(null);
	const isVideoSectionVisible = useIntersectionObserver(videoSection);

	const reviewSection = useRef(null);
	const isReviewSectionVisible = useIntersectionObserver(reviewSection);

	const [openMap, setOpenMap] = useState(false);

	return (
		<div>
			<Helmet>
				<title>
					FIRMENRADL Bike-Leasing | Abgefahren! Coole Bikes für wenig Kohle!
				</title>
				<meta
					name="description"
					content="Jetzt Top-Bike leasen und bis zu 35 % günstiger finanzieren als bei Kauf! Inklusive Reparaturen und Versicherung. Details und Rechenbeispiel auf firmenradl.at"
				/>
				<meta name="theme-color" content="#E0334C" />
			</Helmet>
			<Rechner internal={false} />
			<TextContent
				headline="AUF DIE ZUKUNFT ABFAHREN"
				text="<ul>
    <li><em>Wir</em> sind FIRMENRADL und bieten Arbeitnehmern und Arbeitgebern eine neue, innovative Art der Mobilität.</li>
    <li><em>Du</em> bekommst dein gewünschtes Top-Fahrrad, egal ob normales Radl oder E-Bike, bequem im Leasing direkt über
        deinen Arbeitgeber mittels Gehaltsabzug.
    </li>
    <li><em>Du</em> sparst dir bis zu 35 % zum normalen Kauf, profitierst von inkludierten Versicherungen & Reparaturen und bist
        somit sicher abgefahren günstig unterwegs.
    </li>
</ul>"
				image="assets/filler_mood_lime_1.jpg"
				buttonVisible
				buttonText={"Keine Zeit verlieren und gleich registrieren"}
				buttonLink={getRegisterUrl()}
				imageVisibleByDefault
			/>
			<Steps steps={stepsUser} />
			<section className="suspenseSection videoSection" ref={videoSection}>
				{isVideoSectionVisible && (
					<Suspense
						fallback={<div className="suspenseFallback">Loading...</div>}
					>
						<Video />
					</Suspense>
				)}
			</section>
			<Advantages advantages={advantagesUser} />
			<Brands />
			<section
				className="suspenseSection multiMapSection"
				ref={mapsSection}
				id={"map"}
			>
				{windowSize[1] > 800 ? (
					<>
						{isMapsSectionVisible && (
							<Suspense
								fallback={<div className="suspenseFallback">Loading...</div>}
							>
								<MultiMap type={ETypeOfPartnership.all} hideBanner={false} />
							</Suspense>
						)}
					</>
				) : (
					<>
						<MapPopup open={openMap} closePopup={() => setOpenMap(false)} />
						<div className="mobileMap">
							<button
								className="mobileMapButton"
								onClick={() => setOpenMap(true)}
								type="button"
							>
								Karte mit Radl-Händlern & Arbeitgebern öffnen
							</button>
						</div>
					</>
				)}
			</section>
			<FAQ type="users" />
			<section className="suspenseSection reviewSection" ref={reviewSection}>
				{isReviewSectionVisible && (
					<Suspense
						fallback={<div className="suspenseFallback">Loading...</div>}
					>
						<Reviews />
					</Suspense>
				)}
			</section>
		</div>
	);
}

export default HomePage;
