import React, { useState } from "react";
import { slide as Menu } from "react-burger-menu";
import { NavLink } from "react-router-dom";
import "./Header.scss";

import { getRegisterUrl } from "../helpers/getRegisterUrl";
import useScrollDirection from "../helpers/useScrollDirection";
// @ts-expect-error
import logo from "../logo.svg";
// @ts-expect-error
import logo_red from "../logo_red.svg";

function Header() {
	const scrollDirection = useScrollDirection();
	const [menuOpen, setMenuOpen] = useState(false);

	const closeMenu = () => {
		setTimeout(() => setMenuOpen(false), 300);
	};

	const links = [
		<NavLink
			className={(navData) => (navData.isActive ? "active" : "")}
			to="/"
			onClick={closeMenu}
			key={"link_arbeitnehmer"}
		>
			Arbeitnehmer
		</NavLink>,
		<NavLink
			className={(navData) => (navData.isActive ? "active" : "")}
			to="/arbeitgeber"
			onClick={closeMenu}
			key={"link_arbeitgeber"}
		>
			Arbeitgeber
		</NavLink>,
		<NavLink
			className={(navData) => (navData.isActive ? "active" : "")}
			to="/haendler"
			onClick={closeMenu}
			key={"link_haendler"}
		>
			Radl-Händler
		</NavLink>,
		<NavLink
			className={(navData) => (navData.isActive ? "active" : "")}
			to="/marken"
			onClick={closeMenu}
			key={"link_marken"}
		>
			Marken
		</NavLink>,
		<NavLink
			className={(navData) => (navData.isActive ? "active" : "")}
			to="/ablauf"
			onClick={closeMenu}
			key={"link_ablauf"}
		>
			Ablauf
		</NavLink>,
		<NavLink
			className={(navData) => (navData.isActive ? "active" : "")}
			to="/gewinnspiel"
			onClick={closeMenu}
			key={"link_gewinnspiel"}
		>
			Gewinnspiel
		</NavLink>,
		<NavLink
			className={(navData) => (navData.isActive ? "active" : "")}
			to="/kontakt"
			onClick={closeMenu}
			key={"link_kontakt"}
		>
			Kontakt
		</NavLink>,
		<a
			href={getRegisterUrl()}
			target="_blank"
			className="button"
			rel="noreferrer"
			onClick={closeMenu}
			key={"link_login_register"}
		>
			Login & Registrierung
		</a>,
	];

	return (
		<header
			className={`Header ${scrollDirection === "down" ? "hide" : "show"}`}
		>
			<div className="content">
				<NavLink
					className={(navData) => (navData.isActive ? "active" : "")}
					to="/"
				>
					<picture className={"logo"}>
						<img
							src={logo}
							alt="Logo von Firmenradl.at"
							title="Logo von Firmenradl.at"
							className={"white"}
							width={152}
						/>
						<img
							src={logo_red}
							alt="Logo von Firmenradl.at"
							title="Logo von Firmenradl.at"
							className={"red"}
							width={152}
						/>
					</picture>
				</NavLink>

				<nav className={"desktop"}>
					<ul>
						{links.map((link) => (
							<li key={`link_desktop_${link.key}`}>{link}</li>
						))}
					</ul>
				</nav>
				<nav className={"mobile"}>
					<Menu
						width={"100vw"}
						right
						isOpen={menuOpen}
						onOpen={() => setMenuOpen(true)}
						onClose={() => setMenuOpen(false)}
					>
						{links.map((link) => link)}
					</Menu>
				</nav>
			</div>
		</header>
	);
}

export default Header;
