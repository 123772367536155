import type { Dispatch, SetStateAction } from "react";
import { useOutletContext } from "react-router-dom";
import type {
	IAdvantage,
	IBrand,
	ICMS,
	IFAQEntry,
	IFiles,
	IFirm,
	IInsurance,
	ILeasingDuration,
	ILegalForm,
	IMarker,
	IPartner,
	IResidualvalue,
	IStep,
} from "../types/types";

type ContextType = {
	randomBrands: IBrand[];
	filterPartnersByBrand: Dispatch<SetStateAction<number | false>>;
	filteredPartners: IPartner[];
	/* … spreaded FirmenradlData */
	processingTime: number;
	brands: { [key: string]: IBrand };
	partners: { [key: string]: IPartner };
	firms: { [key: string]: IFirm };
	markers: { [key: string]: IMarker };
	mappedPartnerships: (IPartner | IFirm)[];
	multipleMarkers: number;
	faq: IFAQEntry[];
	faqOld: IFAQEntry[];
	cms: ICMS;
	files: IFiles;
	insurances: IInsurance[];
	leasingDurations: ILeasingDuration[];
	legalForms: ILegalForm[];
	residualValues: IResidualvalue[];
};

function useCachedData() {
	return useOutletContext<ContextType>();
}

export { useCachedData, type ContextType };
