import React from "react";
import { Navigate, createBrowserRouter } from "react-router-dom";
import App, { loader as rootLoader } from "./App";
import { getBaseUrl } from "./helpers/getBaseUrl";
import AblaufPage from "./pages/AblaufPage";
import AdminPage from "./pages/AdminPage";
import BrandsPage from "./pages/BrandsPage";
import CookiesPage from "./pages/CookiesPage";
import DownloadPage from "./pages/DownloadPage";
import ErrorPage from "./pages/ErrorPage";
import FaqPage from "./pages/FAQPage";
import FirmsPage from "./pages/FirmsPage";
import GewinnspielPage from "./pages/GewinnspielPage";
import HaendlermapPage from "./pages/HaendlermapPage";
import HilfeHaendlerPage from "./pages/HilfeHaendlerPage";
import HomePage from "./pages/HomePage";
import ImprintPage from "./pages/ImprintPage";
import KontaktPage from "./pages/KontaktPage";
import PartnersPage from "./pages/PartnersPage";
import PrivacyPage from "./pages/PrivacyPage";
import PureRechnerPage from "./pages/PureRechnerPage";

const basename = getBaseUrl(false, "", true);

const router = createBrowserRouter(
	[
		{
			path: "/",
			element: <App />,
			loader: rootLoader,
			errorElement: <ErrorPage />,
			children: [
				{
					index: true,
					element: <HomePage />,
				},
				{
					path: "arbeitgeber",
					element: <FirmsPage />,
				},
				{
					path: "haendler",
					element: <PartnersPage />,
				},
				{
					path: "gewinnspiel",
					element: <GewinnspielPage />,
				},
				{
					path: "ablauf",
					element: <AblaufPage />,
				},
				{
					path: "faq",
					element: <FaqPage />,
				},
				{
					path: "faq2022",
					element: <FaqPage />,
				},
				{
					path: "download",
					element: <DownloadPage />,
				},
				{
					path: "haendlermap",
					element: <HaendlermapPage />,
				},
				{
					path: "hilfebereich-fur-handler",
					element: <HilfeHaendlerPage />,
				},
				{
					path: "impressum",
					element: <ImprintPage />,
				},
				{
					path: "datenschutzerklarung",
					element: <PrivacyPage />,
				},
				{
					path: "cookie-policy",
					element: <CookiesPage />,
				},
				{
					path: "marken",
					element: <BrandsPage />,
				},
				{
					path: "kontakt",
					element: <KontaktPage />,
				},
				{
					//TODO fix the admin page
					path: "admin",
					element: <AdminPage />,
				},
				{
					path: "embed",
					element: <PureRechnerPage />,
				},
				{
					path: "embed/arbeitnehmer",
					element: <PureRechnerPage />,
				},
				{
					path: "embed/portal",
					element: <PureRechnerPage internal />,
				},
				// TODO I don't understand these redirects
				// Redirects
				{
					path: "index.php/kalkulator",
					element: <Navigate to="/" replace />,
				},
				{
					path: "index.php/faq",
					element: <Navigate to="/faq" replace />,
				},
				{
					path: "index.php/fachhandler-auflistung",
					element: <Navigate to="/haendlermap" replace />,
				},
				{
					path: "index.php/handlermap",
					element: <Navigate to="/haendlermap" replace />,
				},
				{
					path: "index.php/partnerfirmen",
					element: <Navigate to="/arbeitgeber" replace />,
				},
				{
					path: "index.php/ablaufe",
					element: <Navigate to="/ablauf" replace />,
				},
				{
					path: "index.php/impressum",
					element: <Navigate to="/impressum" replace />,
				},
				{
					path: "index.php/fahrradmarken",
					element: <Navigate to="/marken" replace />,
				},
				{
					path: "index.php/cookie-policy",
					element: <Navigate to="/cookie-policy" replace />,
				},
				{
					path: "index.php/datenschutzerklarung",
					element: <Navigate to="/datenschutzerklarung" replace />,
				},
				{
					path: "index.php/hilfebereich-fur-handler",
					element: <Navigate to="/hilfebereich-fur-handler" replace />,
				},
				{
					path: "index.php/jobs",
					element: <Navigate to="/" replace />,
				},
				{
					path: "index.php",
					element: <Navigate to="/" replace />,
				},
			],
		},
	],
	{
		basename,
	},
);

export default router;
