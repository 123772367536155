import { create } from "zustand";
import type { ILocation } from "../types/types";

interface StoreState {
	center: ILocation;
	zoom: number;
	activeWindow: string;
	setCenter: (location: ILocation) => void;
	setZoom: (zoom: number) => void;
	setActiveWindow: (activeWindow: string) => void;
	// @ts-ignore
	// biome-ignore lint/suspicious/noExplicitAny: <explanation>
	handleMapPlaceSelected: (place: any) => void;
	detectLocation: () => void;
}

const useMapStore = create<StoreState>((set, get) => ({
	center: { lat: 47.7903272, lng: 13.514442 },
	activeWindow: "",
	zoom: 10,
	setCenter: (location) => set({ center: location }),
	setZoom: (zoom) => set({ zoom }),
	setActiveWindow: (activeWindow) => set({ activeWindow }),
	handleMapPlaceSelected: (place) => {
		set({
			center: {
				lat: place.geometry.location.lat(),
				lng: place.geometry.location.lng(),
			},
			zoom: 12,
		});
	},
	detectLocation: () => {
		navigator.geolocation.getCurrentPosition(
			(position) => {
				set({
					center: {
						lat: position.coords.latitude,
						lng: position.coords.longitude,
					},
					zoom: 12,
				});
			},
			(error) => {
				console.log(error);
			},
		);
	},
}));

export default useMapStore;
