import React from "react";
import { Helmet } from "react-helmet";
import Rechner from "../components/Rechner";

const PureRechnerPage = ({ internal = false }) => {
	return (
		<div>
			<Helmet>
				<title>Kalkulator | FIRMENRADL</title>
				<meta name="robots" content="noindex, nofollow" />
			</Helmet>
			<Rechner className="embed" internal={internal} />
		</div>
	);
};

export default PureRechnerPage;
