import React, { Suspense } from "react";
import "./styles/MapPopup.scss";
import ETypeOfPartnership from "../../types/enum/type-of-partnerships";

const MultiMap = React.lazy(() => import("./MultiMap"));

type Props = {
	open: boolean;
	closePopup: (b: boolean) => void;
	partnersOnly?: boolean;
};

function MapPopup({ open = false, closePopup, ...rest }: Props) {
	if (!open) return null;

	return (
		<div className="MapPopup">
			<div
				className="MapPopup-Closer"
				onClick={() => closePopup(false)}
				onKeyDown={(e) => e.key === "escape" && closePopup(false)}
			/>
			<div className="MapPopup-Content">
				{open && (
					<Suspense
						fallback={<div className="suspenseFallback">Loading...</div>}
					>
						<MultiMap type={ETypeOfPartnership.all} hideBanner={false} />
					</Suspense>
				)}
			</div>
		</div>
	);
}

export default MapPopup;
