import React from "react";
import { Helmet } from "react-helmet";
import FAQ from "../components/FAQ";
import Spacer from "../components/Spacer";

function FaqPage() {
	return (
		<div>
			<Helmet>
				<title>Häufig gestellte Fragen | FAQ | FIRMENRADL</title>
				<meta
					name="description"
					content="Du bekommst dein gewünschtes Marken-Fahrrad, egal ob normales Radl oder E-Bike, bequem im Leasing direkt über deinen Arbeitgeber mittels Gehaltsabzug."
				/>
			</Helmet>
			<Spacer />
			<FAQ type="all" seeAllFromStart />
		</div>
	);
}

export default FaqPage;
