import type { ReactNode } from "react";
import "./Advantages.scss";
import "./Advantage.scss";
import React from "react";
import { getRegisterUrl } from "../helpers/getRegisterUrl";
import type { IAdvantage } from "../types/types";
import AnimatedKringel from "./AnimatedKringel";
import Image from "./Image";

type Props = {
	location?: string;
	buttonText?: string;
	advantages: IAdvantage[];
};

function Advantages({
	location = "user",
	buttonText = "JETZT REGISTRIEREN & LOSFAHREN",
	advantages,
}: Props) {
	return (
		<div className="Advantages AnimatedKringelParent">
			<AnimatedKringel
				text="Alles meine Vorteile? Abgefahren!"
				wordOffsetDesktop={3}
				wordOffsetMobile={3}
				everyNWordsMobile={4}
				spacerHeight={500}
				marginBottomMobile={"-100vw"}
				pathVar={2}
				scrollSpeedDesktop={1.7}
			/>
			<div className="Advantages-List">
				{advantages.map((advantage) => (
					<Advantage
						key={`advantages_${location}_${advantage.content}`}
						headline={advantage.title}
						img={advantage.image}
						text={
							<div className="content">
								<p
									// biome-ignore lint/security/noDangerouslySetInnerHtml: <explanation>
									dangerouslySetInnerHTML={{ __html: advantage.content }}
								/>
							</div>
						}
					/>
				))}
				<div className="Advantages-Banner">
					<a
						href={getRegisterUrl()}
						className="button"
						target="_blank"
						rel="noreferrer"
					>
						{buttonText}
					</a>
				</div>
			</div>
		</div>
	);
}

function Advantage({
	img,
	headline,
	text,
}: { img: string; headline: string; text: ReactNode }) {
	return (
		<div className="Advantage">
			<div className="Advantage-Text">
				<h2>{headline}</h2>
				{text}
			</div>
			<div className="Advantage-Image">
				<Image
					src={img}
					width={650}
					height={650}
					crop
					// @ts-expect-error
					alt="Vorteil 1"
				/>
			</div>
		</div>
	);
}

export default Advantages;
