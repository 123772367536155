import React from "react";
import { Helmet } from "react-helmet";

function CookiesPage() {
	return (
		<div className="Privacy Rechtliches">
			<Helmet>
				<title>Cookie Policy | FIRMENRADL</title>
				<meta
					name="description"
					content="Wir sind FIRMENRADL und bieten Arbeitnehmern und Arbeitgebern eine neue, innovative Art der Mobilität."
				/>
				<meta name="robots" content="noindex, nofollow" />
				<meta name="theme-color" content="#E0334C" />
			</Helmet>
			<div className="fr-view u-clearfix u-rich-text u-text u-text-3">
				<h1>Cookie Policy</h1>
				<p>
					Diese Website wird betrieben von der INTERSPORT Austria GmbH (FN
					101648h), in der Folge „wir“, „uns“ und „INTERSPORT“, mit Sitz in 4600
					Wels, Flugplatzstraße 10. In dieser Datenschutzerklärung wird von uns
					als Verantwortlichem nach Art. 4 Abs. 7 EU-DSGVO beschrieben, welche
					Cookies wir bei Ihrem Besuch auf unserer Website erheben und zu
					welchem Zweck wir diese verarbeiten, sofern Sie dazu Ihre Zustimmung
					gemäß §96 Abs. 3 TKG erteilt haben.
				</p>
				<p>
					Im Folgenden informieren wir Sie detailliert über den Umfang und den
					Zweck des Einsatzes von Cookies. Lesen Sie daher sowohl diese Cookie –
					Policy, als auch unsere Datenschutzerklärun bitte genau durch, bevor
					Sie unsere Website weiter nutzen und gegebenenfalls Ihre Einwilligung
					zu einer Datenverarbeitung oder dem Einsatz von Cookies geben.&nbsp;
				</p>
				<p>
					Bitte lesen Sie jedenfalls den Teil unsere Datenschutzerklärung, in
					dem wir Ihre Rechte im Zusammenhang mit der Verarbeitung Ihrer Daten
					darstellen!
				</p>
				<p>1. Verwendung von Cookies – Arten von Cookies</p>
				<p>
					a.&nbsp;Bei der bloß informatorischen Nutzung unserer Website, also
					wenn Sie keinen unserer Services nutzen oder uns auf anderem Wege
					Informationen übermitteln, erheben wir nur die personenbezogenen
					Daten, die Ihr Browser unserem Server übermittelt. Sofern Sie also
					unsere Website besuchen möchten, erheben wir die nachfolgend
					angeführten Daten, die für uns technisch erforderlich sind, um Ihnen
					die Website anzuzeigen und deren Stabilität und Sicherheit auf Basis
					unseres berechtigten Interesses gem. Art. 6 Abs. 1 S. 1 lit. f
					EU-DSGVO zu gewährleisten:
				</p>
				<ul>
					<li>IP-Adresse</li>
					<li>Datum und Uhrzeit der Anfrage</li>
					<li>Zeitzonendifferenz zur Greenwich Mean Time (GMT)</li>
					<li>Inhalt der Anforderung</li>
					<li>Zugriffsstatus / http-Statuscode</li>
					<li>Jeweils übertragene Datenmenge</li>
					<li>Website, von der die Anforderung kommt</li>
					<li>Verwendeter Browser</li>
					<li>Betriebssystem und dessen Oberfläche</li>
					<li>Sprache und Version der Browsersoftware</li>
				</ul>
				<p>
					b. Ergänzend zu den zuvor genannten Daten werden bei Ihrer Nutzung
					unserer Website Cookies auf Ihrem Rechner gespeichert; dabei handelt
					es sich um kleine Textdateien, die auf Ihrer Festplatte dem von Ihnen
					verwendeten Browser zugeordnet gespeichert werden und durch welche der
					Stelle, die den Cookie setzt (hier durch uns), bestimmte Informationen
					übermittelt werden. Diese Cookies benötigen wir einerseits um Sie als
					Nutzer der Website zu erkennen und andererseits, um die von Ihnen im
					Zuge der Nutzung unserer Services abgegebenen Reservierungen über den
					Warenkorb nachvollziehbar machen zu können.
				</p>
				<p>
					Man kann grundsätzlich zwischen First Party Cookies, Third Party
					Cookies und Third Party Requests unterscheiden.
				</p>
				<ul>
					<li>First Party Cookies</li>
				</ul>
				<p>
					First Party Cookies werden von uns bzw. unserer Website selbst in
					Ihrem Browser gespeichert, um Ihnen ein bestmögliches Nutzungserlebnis
					zu bieten. Dabei handelt es sich insbesondere um funktionale Cookies,
					wie etwa unsere Warenkorb-Cookies.
				</p>
				<ul>
					<li>Third Party Cookies</li>
				</ul>
				<p>
					Third Party Cookies werden von einem Drittanbieter in Ihrem Browser
					gespeichert. Dabei handelt es sich zumeist um Tracking- oder
					Marketingtools, die einerseits ihr Nutzerverhalten auswerten und zum
					anderen dem Drittanbieter die Möglichkeit bieten, Sie auch auf anderen
					besuchten Websites wiederzuerkennen. Retarget Marketing basiert auf
					der Funktion dieser Cookies.
				</p>
				<ul>
					<li>Third Party Requests</li>
				</ul>
				<p>
					Third Party Request sind alle Anfragen, die Sie als Seitenbenutzer
					über unsere Seite an Dritte stellen – also beispielsweise, wenn Sie
					mit Plugins sozialer Netzwerke agieren oder etwa das Angebot eines
					Bezahlanbieters nutzen. In diesem Fall werden zwar keine Cookies in
					Ihrem Browser gespeichert, es ist aber nicht auszuschließen, dass
					durch die Interaktion personenbezogene Daten an diesen Drittanbieter
					gesendet werden. Aus diesem Grund informieren wir Sie auch im Detail
					in unserer Datenschutzerklärung über die von uns eingesetzten Tools
					&amp; Applikationen.
				</p>
				<p>
					c.&nbsp;Unsere Website(s) nutzen folgende Arten von Cookies, deren
					Umfang und Funktionsweise im Folgenden erläutert werden:
				</p>
				<ul>
					<li>Transiente Cookies</li>
				</ul>
				<p>
					Transiente Cookies werden automatisiert gelöscht, wenn Sie Ihren
					Browser schließen. Dazu zählen insbesondere Session-Cookies, diese
					speichern eine sogenannte Session-ID, mit welcher sich verschiedene
					Anfragen Ihres Browsers der gemeinsamen Sitzung zuordnen lassen.
					Dadurch kann Ihr Rechner wiedererkannt werden, wenn Sie auf unsere
					Website zurückkehren, diese Session-Cookies werden gelöscht, wenn Sie
					sich ausloggen oder den Browser schließen.
				</p>
				<ul>
					<li>Persistente Cookies</li>
				</ul>
				<p>
					Persistente Cookies werden automatisiert nach einer vorgegebenen Dauer
					gelöscht, die sich je nach Cookie unterscheiden kann. Sie selbst
					können die Cookies jedoch in Ihren Browsereinstellungen jederzeit
					löschen.
				</p>
				<p>
					d. Es ist Ihnen möglich, Ihre Browsereinstellungen dahingehend
					abzuändern, dass Sie beispielsweise die Annahme von
					Third-Party-Cookies oder allen Cookies ablehnen. In diesem Fall müssen
					wir Sie jedoch darauf hinweisen, dass Sie eventuell nicht mehr alle
					Funktionen unserer Website nutzen können.
				</p>
				<p>
					e.&nbsp;Wir setzen selbst auch Cookies ein, um Sie für Folgebesuche
					identifizieren zu können, falls Sie über einen Account bei uns
					verfügen – andernfalls müssen Sie sich bei jedem Besuch neu einloggen.
				</p>
				<p>3. Nutzung von Google – Tools</p>
				<p>
					Auf unserer Website verwenden wir verschiedene Webanalyse- und
					Marketingtools von Google. Zu diesem Zweck speichern wir folgende
					Cookies und Pixel:
				</p>
				<ul>
					<li>_gat (Speicherdauer 1 Minute)</li>
					<li>_ga (Speicherdauer 2 Jahre)</li>
					<li>_gid (Speicherdauer 24 Stunden)</li>
					<li>collect (Speicherdauer während der Session)</li>
					<li>ads/ga-audiences (Speicherdauer während der Session)</li>
					<li>IDE (Speicherdauer bis zu 1 Jahr)</li>
					<li>NID (Speicherdauer bis zu 6 Monate)</li>
					<li>1P_JAR (Speicherdauer 1 Monat)</li>
				</ul>
				<p>
					Sie können die Installation der Cookies von Google auf verschiedene
					Weise verhindern, insbesondere auch durch eine entsprechende Ablehnung
					des Services beim Besuch unserer Website oder durch eine entsprechende
					Einstellung Ihrer Browser-Software. Details zur individuellen
					Ablehnung finden Sie bei der Beschreibung des jeweiligen Tools. Wir
					weisen Sie darauf hin, dass Sie in diesem Fall gegebenenfalls nicht
					alle Funktionen unserer Website vollumfänglich nutzen können.
				</p>
				<p>
					Aufgrund der nachstehend beschriebenen und von uns eingesetzten
					Webanalyse- und Marketingtools baut Ihr Browser automatisch eine
					direkte Verbindung mit dem Server von Google auf. Wir haben keinen
					Einfluss auf den Umfang der in diesem Rahmen übermittelten
					Informationen und die weitere Verwendung der Daten durch Google. Der
					uns bekannte Umfang und die Zwecke der Verarbeitung werden nachstehend
					bei den jeweiligen Tools beschrieben. Sofern Sie bei einem Dienst von
					Google registriert sind, kann Google den Besuch Ihrem Account zuordnen
					– doch selbst wenn Sie nicht bei Google registriert sind bzw. sich
					nicht eingeloggt haben, besteht die Möglichkeit, dass der Anbieter
					Ihre IP-Adresse in Erfahrung bringt und speichert.
				</p>
				<p>
					Weitere Informationen zu Zweck und Umfang der Datenerhebung und ihrer
					Verarbeitung durch Google sowie weitere Informationen zu Ihren
					diesbezüglichen Rechten und Einstellungsmöglichkeiten erhalten Sie
					bei: Google Inc., 1600 Amphitheater Parkway, Mountainview, California
					94043, USA, wobei sich Google der Anwendung des Privacy-Shields
					unterworfen hat. Details dazu finden Sie unter:
				</p>
				<p>http://www.google.com/intl/de/policies/privacy</p>
				<p>https://services.google.com/sitestats/de.html</p>
				<p>http://www.networkadvertising.org</p>
				<p>https://www.privacyshield.gov/EU-US-Framework</p>
				<p>
					a.&nbsp;Auf unserer Website verwenden wir das Webanalyse- und
					Marketingtool&nbsp;Google Analytics. Google Analytics ist einen
					Webanalysedienst der Google Inc., 1600 Amphitheatre Parkway, Mountain
					View, CA 94043, USA. Die dabei verwendeten Cookies erzeugen
					Informationen über Ihre Benutzung dieser Website und werden in der
					Regel an einen Server von Google übertragen und dort gespeichert.
				</p>
				<p>
					In unserem Auftrag benutzt Google diese Informationen, um Ihre Nutzung
					unserer Website auszuwerten, um Reports über die Websiteaktivitäten
					zusammenzustellen und um weitere mit der Websitenutzung und der
					Internetnutzung verbundene Dienstleistungen gegenüber dem
					Websitebetreiber zu erbringen. Sollten Sie weiterführende
					Informationen zu Art, Umfang und Zweck der von Google erhobenen Daten
					informieren wollen, empfehlen wir Ihnen, deren Datenschutzbestimmungen
					zu lesen.
				</p>
				<p>
					b. &nbsp;Wir nutzen auch das Angebot von Google Ads, um mit Hilfe von
					Werbeanzeigen auf externen Webseiten auf unsere attraktiven Angebote
					und Produkte aufmerksam zu machen. Durch dieses Tool können wir auch
					genau ermitteln, in welchem Verhältnis die einzelnen Werbemaßnahmen
					bezogen auf konkrete Kampagnen stehen. Wir verfolgen damit das
					Interesse, Ihnen maßgeschneiderte Werbung entsprechend Ihrer
					Interessen anzuzeigen und in diesem Rahmen eine faire Berechnung von
					Werbekosten zu erreichen.
				</p>
				<p>
					Die Ihnen angezeigten Werbungen werden durch Google über sogenannte
					„Ad Server“ geschaltet. Zu diesem Zweck setzen wir Ad Server Cookies
					ein, durch die bestimmte Parameter, wie Einblendung der Anzeigen oder
					Klicks durch die Nutzer, gemessen werden können, um den Erfolg von
					Werbekampagnen zu eruieren. Dies funktioniert so, dass in den Fällen,
					in denen Sie über eine Google-Anzeige auf unsere Website gelangen, von
					Google Ads ein Cookie in ihrem Browser gespeichert wird, der in der
					Regel nach 30 Tagen seine Gültigkeit verliert. Dieser Cookie dient
					nicht dazu, Sie persönlich zu identifizieren, sondern um zu
					Analysezwecken die Unique Cookie-ID, die Anzahl von Ad Impressions pro
					Platzierung, die letzte Impression (relevant für
					Post-View-Conversions) sowie Opt-out-Informationen zu speichern.
				</p>
				<p>
					Wir selbst erheben und verarbeiten im Rahmen des Einsatzes von Google
					Ads keine personenbezogenen Daten, sondern erhalten von Google
					lediglich statistische Auswertungen, anhand der wir bestimmen können,
					welche der von uns eingesetzten Werbekampagnen besonders effektiv
					sind.
				</p>
				<p>
					Sie können die Teilnahme an diesem Tracking-Verfahren ergänzend zu den
					bereits beschriebenen Maßnahmen dadurch verhindern, dass Sie Cookies
					für Conversion-Tracking deaktivieren, indem Sie Ihren Browser so
					einstellen, dass Cookies von der Domain „www.googleadservices.com“
					blockiert werden, oder durch dauerhafte Deaktivierung in Ihren
					Browsern Firefox, Internetexplorer oder Google Chrome unter dem Link
					http://www.google.com/settings/ads/plugin.
				</p>
				<p>
					c.&nbsp;Ergänzend nutzen wir die Anwendung&nbsp;Google Remarketing,
					dabei handelt es sich um ein Verfahren, mit dem wir Sie auch dann
					ansprechen möchten, wenn Sie unsere Website wieder verlassen. Wir
					können Ihnen in diesem Rahmen nach Besuch unserer Website bei Ihrer
					weiteren Internetnutzung unsere Werbeanzeigen einblenden. Dies erfolgt
					mittels in Ihrem Browser gespeicherter Cookies, über die Ihr
					Nutzungsverhalten bei Besuch verschiedener Websites durch Google
					erfasst und ausgewertet wird.
				</p>
				<p>
					So kann beispielsweise von Google Ihr vorheriger Besuch unserer
					Website festgestellt werden, es findet jedoch laut Google beim
					Remarketing eine Pseudonymisierung der verarbeiteten personenbezogenen
					Daten statt.
				</p>
				<p>
					d.&nbsp;Diese Webseite nutzt weiterhin das Onlinemarketing
					Tool&nbsp;DoubleClick by Google. DoubleClick nutzt Cookies, um für
					Nutzer relevante Anzeigen zu schalten, die Berichte zur
					Kampagnenleistung zu verbessern oder um zu vermeiden, dass ein Nutzer
					die gleichen Anzeigen mehrmals sieht. Über eine Cookie-ID erfasst
					Google, welche Anzeigen in welchem Browser geschaltet werden und kann
					so verhindern, dass diese mehrfach angezeigt werden. Darüber hinaus
					kann DoubleClick mithilfe von Cookie-IDs sog. Conversions erfassen,
					die Bezug zu Anzeigenanfragen haben. Das ist etwa der Fall, wenn ein
					Nutzer eine DoubleClick-Anzeige sieht und später mit demselben Browser
					die Website des Werbetreibenden aufruft und dort etwas kauft. Laut
					Google enthalten DoubleClick-Cookies keine personenbezogenen
					Informationen.
				</p>
				<p>
					Sie können die Teilnahme an diesem Tracking-Verfahren ergänzend zu den
					bereits beschriebenen Maßnahmen dadurch verhindern, dass Sie Cookies
					für Conversion-Tracking deaktivieren, indem Sie Ihren Browser so
					einstellen, dass Cookies von der Domain „www.googleadservices.com“
					blockiert werden, oder durch dauerhafte Deaktivierung in Ihren
					Browsern Firefox, Internetexplorer oder Google Chrome unter dem Link
					http://www.google.com/settings/ads/plugin.
				</p>
				<p>4. Nutzung von Facebook – Tools</p>
				<p>
					Wir verwenden die Remarketing-Funktion „Custom Audiences“ der Facebook
					Inc. („Facebook“). Dadurch können Nutzern der Website im Rahmen des
					Besuchs des sozialen Netzwerkes Facebook oder anderer das Verfahren
					ebenfalls nutzende Websites interessenbezogene Werbeanzeigen
					(„Facebook-Ads“) dargestellt werden. Wir verfolgen damit das
					Interesse, Ihnen Werbung anzuzeigen, die für Sie von Interesse ist, um
					unsere Website für Sie interessanter zu gestalten.
				</p>
				<p>Die dabei gespeicherten Cookies und Pixel sind</p>
				<ul>
					<li>fr (Speicherdauer 3 Monate)</li>
					<li>tr (Speicherdauer während der Session)</li>
				</ul>
				<p>
					Durch dieses Marketingtool baut Ihr Browser automatisch eine direkte
					Verbindung mit dem Server von Facebook auf. Wir haben keinen Einfluss
					auf den Umfang der in diesem Rahmen übermittelten Informationen und
					die weitere Verwendung der Daten durch Facebook. Sofern Sie bei
					Facebook registriert sind, kann Facebook den Besuch Ihrem Profil
					zuordnen – doch selbst wenn Sie nicht bei Facebook registriert sind
					bzw. sich nicht eingeloggt haben, besteht die Möglichkeit, dass der
					Anbieter Ihre IP-Adresse in Erfahrung bringt und speichert. Weitere
					Informationen zur Datenverarbeitung durch Facebook erhalten Sie unter
					https://www.facebook.com/about/privacy.
				</p>
				<p>
					Die Ablehnung der Funktion „Facebook Custom Audiences“ ist beim Besuch
					unserer Website sowie für eingeloggte Nutzer
					unter&nbsp;https://www.facebook.com/settings/?tab=ads#_möglich.
				</p>
				<p>5. Nutzung von IBM – Tools</p>
				<p>
					Ein weiteres Trackingtool auf unserer Website ist Watson von IBM, 1
					New Orchard Road, Armonk, NY 10540, USA. Zur Verwendung dieses Tools
					speichern wir folgende Cookies:
				</p>
				<ul>
					<li>
						com.silverpop.IMA.page_visit (Speicherdauer während der Session)
					</li>
					<li>com.silverpop.IMA.session (Speicherdauer während der Session)</li>
					<li>com.silverpop.IMAWebCookie (Speicherdauer bis zu 3 Jahre)</li>
					<li>WTS/event.jpg (Speicherdauer während der Session)</li>
				</ul>
				<p>
					Watson erfasst und analysiert das Verhalten von Nutzern unserer Seite,
					um behavioral targeting zu Marketingzwecken zu ermöglichen. In diesem
					Rahmen erfassen wir automatisiert das Verhalten der Websitenutzer im
					Umgang mit unseren Newslettern und beim Besuch auf unserer Website.
					Wenn Sie sich beispielsweise über einen bestimmten Zeitraum für
					gewisse Produkte interessieren und daher länger auf einer gewissen
					Subpage unseres Webauftritts aufhalten, gehen wir davon aus, dass
					dieses Produkt für Sie interessant ist und zeigen Ihnen vergleichbare
					Angebote im Browser oder – falls Sie dazu Ihre Zustimmung erteilt
					haben – im Rahmen eines Mailings an die uns bekannt gegebene
					E-Mailadresse an.
				</p>
				<p>
					Wenn Sie auf einen an Sie gerichteten Newsletter reagieren, in dem Sie
					einen darin zur Verfügung gestellten Link anklicken und in weiterer
					Folge unsere Website besuchen erhalten wir demgemäß ebenfalls die
					Information, dass das Ihnen präsentierte Produkt oder Angebot von
					Interesse war. Durch diese und ähnliche Informationen ist es uns –
					bzw. dem von uns eingesetzten Tool – möglich, Ihr Verhalten auf
					unserer Website nach gewissen Kriterien zu filtern, um Ihnen
					automatisiert passende Angebote zu kommen zu lassen.
				</p>
				<p>
					Mehr Informationen zu den Webtrackingcookies von IBM finden Sie
					unter&nbsp;https://www.ibm.com/support/knowledgecenter/en/SSWU4L/Reporting/imc_Reporting/Reporting_q_a_watson_assistant/What_are_Web_Tracking_Cookies.html
				</p>
				<p>
					Sie können die Installation dieser Tracking-Cookies auf verschiedene
					Weise verhindern, insbesondere auch durch eine entsprechende Ablehnung
					des Services beim Besuch unserer Website oder durch eine entsprechende
					Einstellung Ihrer Browser-Software.
				</p>
				<p>6. Nutzung von Awin – Tools</p>
				<p>
					Ergänzend zu Google Ads kooperieren wir im Bereich Werbeanzeigen auf
					externen Websites auch mit der Awin AG, Eichhornstraße 3, 10785
					Berlin, www.awin.com. Ähnlich wie Google Ads bietet auch Awin die
					Möglichkeit, bezahlte Anzeigen auf Drittseiten anzuzeigen, über die
					ein Nutzer auf unser Warensortiment und unsere Angebote aufmerksam
					gemacht und auf unsere Website geleitet wird. &nbsp;
				</p>
				<p>
					Die Ihnen angezeigten Werbungen werden über sogenannte „Ad Server“
					geschaltet, wo durch bestimmte Cookies verschiedene Parameter, wie
					Einblendung der Anzeigen oder Klicks durch die Nutzer, gemessen werden
					können, um den Erfolg von Werbekampagnen zu eruieren.
				</p>
				<p>
					Wir selbst erheben und verarbeiten im Rahmen des Einsatzes von Awin
					keine personenbezogenen Daten, sondern erhalten lediglich statistische
					Auswertungen, anhand der wir bestimmen können, welche der von uns
					eingesetzten Werbekampagnen besonders effektiv sind.
				</p>
				<p>
					7. Rechtsgrundlage für die Nutzung von Cookies und die Verarbeitung
					personenbezogener Daten einschließlich Profiling
				</p>
				<p>
					Sämtliche in dieser Cookie-Policy angeführten Webanalyse- und
					Marketingcookies und die damit erhobenen Daten verarbeiten wir aus
					berechtigtem Interesse gemäß Art. 6 Abs. 1 lit. f EU-DSGVO zu
					Marketingzwecken. (siehe auch Erwägungsgrund 47 zur EU-DSGVO) Bevor
					wir Cookies nutzen, durch die personenbezogene Daten erhoben werden,
					holen wir jedoch zusätzlich auch Ihre Einwilligung ein, um die
					gesetzlichen Erfordernisse des § 96 Abs. 3 TKG zu erfüllen.&nbsp;
				</p>
				<p>
					Im Zuge der von uns verwendeten Remarketing und Targeted Marketing –
					Maßnahmen kommt es anlassbezogen auch zu Profiling.
				</p>
				<p>
					„Profiling“ ist gem. Art. 4 EU-DSGVO jede Art der automatisierten
					Verarbeitung personenbezogener Daten, die darin besteht, dass diese
					personenbezogenen Daten verwendet werden, um bestimmte persönliche
					Aspekte, die sich auf eine natürliche Person beziehen, zu bewerten,
					insbesondere um Aspekte bezüglich Arbeitsleistung, wirtschaftliche
					Lage, Gesundheit, persönliche Vorlieben, Interessen, Zuverlässigkeit,
					Verhalten, Aufenthaltsort oder Ortswechsel dieser natürlichen Person
					zu analysieren oder vorherzusagen.
				</p>
				<p>
					Dieses Profiling ist zulässig, da Sie bzw. Ihre Interessen dadurch
					nicht erheblich beeinträchtigt werden. Die Datenschutzgruppe 29 hat am
					03.10.2017 Guidelines zu der automatisierten Entscheidungsfindung im
					Einzelfall und zum Profiling veröffentlicht, in denen unter II.B.
					ausdrücklich auf das Online-Marketing und die dort verwendeten Tools
					eingegangen wird. Die Datenschutzgruppe 29 geht dabei davon aus, dass
					– vorbehaltlich Einzelfallprüfung – in vielen typischen Fällen
					targeted advertising – also zielgerichtete Werbung aufgrund
					beispielsweise demografischer Profile – nicht unter das Verbot des
					Art. 22 EU-DSGVO zu subsumieren ist.
				</p>
				<p>
					Da wir ein berechtigtes Interesse daran haben, Ihnen die bestmöglichen
					Empfehlungen für Produkte aus unserem breiten Warensortiment
					aussprechen zu können, nutzen wir daher die dafür zur Verfügung
					stehenden Tools der oben im Detail angeführten Anbieter.
					Selbstverständlich treffen wir angemessene Maßnahmen, um Ihre Rechte
					und Freiheiten zu wahren.
				</p>
			</div>
		</div>
	);
}

export default CookiesPage;
