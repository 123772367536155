import React, { Suspense, useRef } from "react";
import { Helmet } from "react-helmet";
import Advantages from "../components/Advantages";
import FAQ from "../components/FAQ";
import Hero from "../components/Hero";
import Spacer from "../components/Spacer";
import { advantagesPartner } from "../data/static_data";
import { getRegisterUrl } from "../helpers/getRegisterUrl";
import useIntersectionObserver from "../helpers/useInteractionObserver";
import ETypeOfPartnership from "../types/enum/type-of-partnerships";

const MultiMap = React.lazy(() => import("../components/map/MultiMap"));

const PartnersPage = () => {
	const mapsSection = useRef(null);
	const isMapsSectionVisible = useIntersectionObserver(mapsSection);

	return (
		<div>
			<Helmet>
				<title>Radl-Händler & Bike-Fachhändler | FIRMENRADL</title>
				<meta
					name="description"
					content="Du bist Fachhändler und willst FIRMENRADL-Partner werden? Abgefahren! Entdecke jetzt die vielen Vorteile und bring auch dein Business ins Rollen."
				/>
				<meta name="theme-color" content="#DBE283" />

				<meta
					property="og:title"
					content="Radl-Händler & Bike-Fachhändler | FIRMENRADL Bike-Leasing"
				/>
				<meta
					property="og:image"
					content="img/assets/mood_lime_full.jpg?w=512"
				/>
				<meta
					property="og:description"
					content="Du bist Fachhändler und willst FIRMENRADL-Partner werden? Abgefahren! Entdecke jetzt die vielen Vorteile und bring auch dein Business ins Rollen."
				/>
			</Helmet>
			<Hero
				headline="FITTE BENEFITS"
				text="<p>Motivierte, gesunde Mitarbeitende sind für jedes Unternehmen Gold wert. Lenk deine Augen also auf die vielen abgefahrenen Vorteile, die FIRMENRADL dir als Partner bieten kann.</p>"
				image="assets/mood_lime_crop.jpg"
				imageMobile="assets/mood_lime_full.jpg"
				buttonVisible
				buttonText={"Keine Zeit verlieren und gleich registrieren!"}
				buttonLink={getRegisterUrl()}
			/>
			<Advantages
				location="partners"
				buttonText="Jetzt Partner werden"
				advantages={advantagesPartner}
			/>
			<Spacer />
			<section className="suspenseSection multiMapSection" ref={mapsSection}>
				{isMapsSectionVisible && (
					<Suspense
						fallback={<div className="suspenseFallback">Loading...</div>}
					>
						<MultiMap type={ETypeOfPartnership.haendler} hideBanner={true} />
					</Suspense>
				)}
			</section>
			<FAQ type="partners" />
		</div>
	);
};

export default PartnersPage;
