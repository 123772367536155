import React from "react";
import { Helmet } from "react-helmet";
import { useCachedData } from "../hooks/useCachedData";

function PrivacyPage() {
	const { cms } = useCachedData();

	return (
		<div className={"Privacy Rechtliches"}>
			<Helmet>
				<title>Datenschutzerklärung | FIRMENRADL</title>
				<meta
					name="description"
					content="Wir sind FIRMENRADL und bieten Arbeitnehmern und Arbeitgebern eine neue, innovative Art der Mobilität."
				/>
				<meta name="robots" content="noindex, nofollow" />
				<meta name="theme-color" content="#E0334C" />
			</Helmet>
			<div
				className="fr-view u-clearfix u-rich-text u-text u-text-3"
				// biome-ignore lint/security/noDangerouslySetInnerHtml: <explanation>
				dangerouslySetInnerHTML={{ __html: cms.privacy }}
			/>
			<div>
				<a href="/cms/pdf/privacy">Datenschutzerklärung herunterladen.</a>
			</div>
		</div>
	);
}

export default PrivacyPage;
