// @ts-nocheck
import { arrayMoveImmutable } from "array-move";
import React, { useState } from "react";
import { Draggable } from "react-drag-reorder";
import { Helmet } from "react-helmet";
import Spacer from "../components/Spacer";

import "./Admin.scss";
import axios from "axios";
import AdminFaq from "../adminComponents/AdminFaq";
import AdminPrivacy from "../adminComponents/AdminPrivacy";
import FAQ from "../components/FAQ";
import { getBaseUrl } from "../helpers/getBaseUrl";

const Admin = () => {
	const [password, setPassword] = useState(
		sessionStorage.getItem("password") !== null
			? sessionStorage.getItem("password")
			: "",
	);
	const [authenticated, setAuthenticated] = useState(false);

	const [faq, setFaq] = useState([]);
	const [cms, setCms] = useState({ privacy: "" });
	const [fetched, setFetched] = useState(false);
	const [feedbackMessage, setFeedbackMessage] = useState(null);

	const [currentView, setCurrentView] = useState("reorder");

	// console.log("password", password, sessionStorage.getItem("password"));
	// console.log(cms);

	const refreshData = (e, newFaq = false) => {
		if (e) {
			e.preventDefault();
		}
		const url = getBaseUrl(true, "/editStaticData", false, true);
		sessionStorage.setItem("password", password);
		// console.log("refreshing to", url, authenticated, password);
		axios
			.post(
				url,
				{
					password: password,
					faq: newFaq ? newFaq : faq,
					update: authenticated,
					cms: cms,
				},
				{
					headers: {
						"Content-Type": "application/json",
					},
				},
			)
			.then((response) => {
				// console.log("response", response.data);
				if (response.data.authenticated) {
					setAuthenticated(true);
					setFaq(response.data.faq);
					setCms(response.data.cms);
					sessionStorage.setItem("password", password);
					setFeedbackMessage("Erfolgreich gespeichert.");
					setTimeout(() => {
						setFeedbackMessage(null);
					}, 2000);
				}
			});
	};

	const addNewFaq = () => {
		const newData = [...faq];
		newData.push({
			title: "",
			body: "",
			users: true,
			firms: true,
			partners: true,
		});
		setFaq(newData);
	};

	const onDeleteFaq = (index) => {
		const newData = [...faq];
		newData.splice(index, 1);
		setFaq(newData);
	};

	const onChangeFaq = (index, name, value) => {
		const newData = [...faq];
		newData[index][name] = value;
		setFaq(newData);
	};

	const onReorderFaq = (currentPos, newPos) => {
		const newFaq = arrayMoveImmutable(faq, currentPos, newPos);
		setFaq(newFaq);
		refreshData(null, newFaq);
	};

	const faqRendered = [];
	faq.forEach((element, index) => {
		faqRendered.push(
			<AdminFaq
				key={`faq_${element.title}`}
				faq={element}
				index={index}
				onChange={(index, name, value) => onChangeFaq(index, name, value)}
				onDelete={(index) => onDeleteFaq(index)}
			/>,
		);
	});

	if (!fetched) {
		refreshData(null);
		setFetched(true);
	}

	return (
		<div>
			<Helmet>
				<title>Häufig gestellte Fragen | FAQ | FIRMENRADL</title>
				<meta
					name="description"
					content="Du bekommst dein gewünschtes Marken-Fahrrad, egal ob normales Radl oder E-Bike, bequem im Leasing direkt über deinen Arbeitgeber mittels Gehaltsabzug."
				/>
			</Helmet>
			<Spacer />

			{!authenticated ? (
				<div className="adminInterface login">
					<form onSubmit={(e) => refreshData(e)}>
						<h1>Admin Interface</h1>
						<div className="row">
							<input
								type="password"
								value={password}
								onChange={(e) => setPassword(e.target.value)}
							/>
							<button type={"submit"}>Login</button>
						</div>
					</form>
				</div>
			) : (
				<div className="adminInterface">
					<form onSubmit={(e) => refreshData(e)}>
						<h1>Admin Bereich</h1>

						<div className="tabs">
							<div
								className={`tab ${currentView === "reorder" && "active"}`}
								onClick={() => setCurrentView("reorder")}
								onKeyDown={(e) =>
									e.key === "space" && setCurrentView("reorder")
								}
							>
								FAQ anordnen
							</div>
							<div
								className={`tab ${currentView === "edit" && "active"}`}
								onClick={() => setCurrentView("edit")}
								onKeyDown={(e) => e.key === "space" && setCurrentView("edit")}
							>
								FAQ bearbeiten
							</div>
							<div
								className={`tab ${currentView === "preview" && "active"}`}
								onClick={() => setCurrentView("preview")}
								onKeyDown={(e) =>
									e.key === "space" && setCurrentView("preview")
								}
							>
								FAQ Vorschau
							</div>
							<div
								className={`tab ${currentView === "privacy" && "active"}`}
								onClick={() => setCurrentView("privacy")}
								onKeyDown={(e) =>
									e.key === "space" && setCurrentView("privacy")
								}
							>
								Datenschutz
							</div>
						</div>

						<br />
						<br />

						<div className="buttonbar">
							<div className="row">
								<button type={"submit"}>Speichern</button>
								<button type={"button"} onClick={(e) => refreshData(e)}>
									Aktualisieren
								</button>
								{feedbackMessage !== null && <span>{feedbackMessage}</span>}
							</div>
						</div>
					</form>

					{currentView === "reorder" && (
						<div>
							<Draggable onPosChange={onReorderFaq}>
								{faq.map((faqItem, idx) => {
									return (
										<div
											key={faqItem.index ? faqItem.index : `generated_${idx}`}
											className="flex-item draggable"
											draggable={true}
										>
											<div className="text">{faqItem.title}</div>

											{faqItem.users && (
												<div className="pill an">Arbeitnehmer</div>
											)}
											{faqItem.firms && (
												<div className="pill ag">Arbeitgeber</div>
											)}
											{faqItem.partners && (
												<div className="pill fh">Händler</div>
											)}
										</div>
									);
								})}
							</Draggable>
						</div>
					)}

					{currentView === "edit" && (
						<div>
							<button
								onClick={() => addNewFaq()}
								className={"button"}
								type={"button"}
							>
								Neue FAQ
							</button>
							<div className="faq-list">{faqRendered}</div>
							<button
								onClick={() => addNewFaq()}
								className={"button"}
								type={"button"}
							>
								Neue FAQ
							</button>
						</div>
					)}

					{currentView === "preview" && (
						<div>
							<FAQ faq={{ ...faq }} />
						</div>
					)}

					{currentView === "privacy" && (
						<div>
							<AdminPrivacy
								content={cms.privacy}
								onChange={(value) => setCms({ ...cms, privacy: value })}
							/>
						</div>
					)}
				</div>
			)}
		</div>
	);
};

export default Admin;
