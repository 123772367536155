import React from "react";
import "./Hero.scss";
import Image from "./Image";

type Props = {
	headline: string;
	text: string;
	image: string;
	imageMobile: string;
	buttonVisible?: boolean;
	buttonText?: string;
	buttonLink?: string;
};

function Hero(props: Props) {
	const {
		headline,
		text,
		image,
		imageMobile,
		buttonVisible = false,
		buttonText = "",
		buttonLink = "",
	} = props;

	// get window width and height for image resizing
	let width = Math.round(window.innerWidth * 1.2);
	let height = Math.round(window.innerHeight * 1.2);

	// reducing width and height to a multiple of 64 to achieve less caching and resizing
	width = width - (width % 64);
	height = height - (height % 64);

	return (
		<div className="Hero">
			<div className="Hero-Image">
				<Image
					src={window.innerWidth > 800 ? image : imageMobile}
					width={width}
					height={height}
					crop={height > width}
					// @ts-expect-error
					alt={headline}
					visibleByDefault
				/>
			</div>
			<div className="Hero-Text">
				<h1>{headline}</h1>
				<div
					// biome-ignore lint/security/noDangerouslySetInnerHtml: <explanation>
					dangerouslySetInnerHTML={{ __html: text }}
				/>
				{buttonVisible ? (
					<a
						href={buttonLink}
						className="button"
						target="_blank"
						rel="noreferrer"
					>
						{buttonText}
					</a>
				) : null}
			</div>
		</div>
	);
}

export default Hero;
