import { useMemo, useState } from "react";
import type {ICalculatorFromInitialValues, ICalculatorInput} from "../types/types";

function useCalculateParams(initialValues: ICalculatorFromInitialValues) {
	const [form, setForm] = useState(initialValues);

	const calculationParams = useMemo<string>(() => {
		const body: ICalculatorInput = {
			Einkommen: form.einkommen,
			FahrradPreis: form.price,
			Versicherung: Number(form.insuranceType),
			Laufzeit: Number(form.leasingDuration),
			Vorsteuerabzug: form.vorsteuerabzug,
			SvAbzug: true,
			Restwertvariante: Number(form.residualValue),
			Pendlerpauschale:
				form.pendlerpauschaleActive && form.pendlerpauschale
					? {
							Id: 1,
							Km: form.pendlerpauschale,
						}
					: {
							Id: 1,
							Km: form.pendlerpauschale,
						},
			Familienbonus:
				form.familienbonusActive && form.fbBis17 + form.fbAb18
					? {
							Id: 1,
							KinderBis17: form.fbBis17,
							KinderAb18: form.fbAb18,
						}
					: {
							Id: 1,
							KinderBis17: form.fbBis17,
							KinderAb18: form.fbAb18,
						},
		};

		return JSON.stringify(body);
	}, [form]);

	return { form, calculationParams, setForm };
}

export default useCalculateParams;
